
const getUrl = () => {
  if (process.env.REACT_APP_HOST_ENV !== 'local') {
    return ''
  } else {
    return process.env.REACT_APP_API_ENDPOINT
  }
}

const commonUrls = {
  getCategorisedCountryList: `${getUrl()}/api/data/v1/country-list/`,
  getDomesticOriginList: `${getUrl()}/api/data/v2/get_domestic_travel_cities/origin/`,
  getDomesticDestinationList: `${getUrl()}/api/data/v2/get_domestic_travel_cities/destination/`,
  getPackageList: `${getUrl()}/api/v1/purchase/consumer_svc/get_package_list/`,
  getProviceList: `${getUrl()}/api/data/v2/province_name/list/`,
  getPaymentMethods: `${getUrl()}/api/purchase/v1/payment_methods/`,
  getWorkshopProvince: `${getUrl()}/api/service-center/v1/province-list/`,
  getPaymentStatus: (draftId) => `${getUrl()}/api/purchase/v1/payment_status/?ref_id=${draftId}`,
  getKotaList: (province) => `${getUrl()}/api/data/v2/city_name/list/?province_code=${province}`,
  getWorkshopCityList: (province) => `${getUrl()}/api/service-center/v1/city-list/?province=${province}`,
  getWorkshopList: (city,page,type) => `${getUrl()}/api/service-center/v1/list/?city=${city}&page=${page}&type=${type}`,
  getBenefitDoc: (staticDataParams) => `${getUrl()}/api/v1/purchase/preview-data/?${staticDataParams}`,
  getTravelDraftData: (draft_id) => `${getUrl()}/api/purchase/v1/get_draft/?ref_id=${draft_id}`,
  getPreviewData: (product_code, data_type) => `${getUrl()}/api/v1/purchase/preview-data/?product_code=${product_code}&data_type=${data_type}`,
  getFinanceReport: (year) => `${getUrl()}/api/report/v1/get-report-list?year=${year}`,
};

const otpUrls = {
  sendOtpUrl: `${getUrl()}/api/auth/v1/send-otp/`,
  verifyOTPUrl: `${getUrl()}/api/auth/v1/verify-otp-and-get-session/?request=web`,
}

export {commonUrls, otpUrls};
