export const imagesLink = {
    heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Hero%20Image%20Struktur%20Organisasi.png',
    edySetiadi: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Edy%20Setiadi.png',
    yasrilRasyid: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Yasril%20Rasyid.png',
    michaelAlvin: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Michael%20Alvin.png',
    cleosentRanding: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Cleosent%20Randing.png',
    suhermanDarmawan: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Suherman%20Darmawan.png',
    makkiIbrahim: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Makki%20Ibrahim.png',
    organizationStructure: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Organization%20Structure%202.png',
    commissionerGraph: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Komite%20di%20bawah%20dewan%20komisaris%202.png',
    directionGraph: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Komite%20di%20bawah%20direksi%202.png'
}