import React, { useCallback, useEffect, useState } from "react";
import {
  CardDeatilCtn,
  CardDeatilTitle,
  CardTitle,
  HeaderText,
  ReviewOrderCtn,
  ReviewOrderdDetailsCtn,
  ReviewOrderWrapper,
  SecondHeaderText,
  TravelHolderDetails,
  PoliciesInfoCtn,
  FooterTnCCtn,
  FooterCheckCtn,
  FooterText,
  TnCAcceptBtn,
} from "./elements";
import TravelHeader from "../../components/TravelHeader";
import TravelFooter from "../../components/TravelFooter";
import Timeline from "../../components/TravelTimeline";
import TravelDetails from "./components/travelDetails";
import {
  Benefitdata,
  BenefitPackageData,
} from "../../lib/travel/benefitDetailsCardData";
import PolicyHolderInfoCard from "../../components/PolicyHolderInfoCard";
import holderCardProcessor from "../../lib/travel/holderCardProcessor";
import insuredCardProcessor from "../../lib/travel/insuredCardProcessor";
import PaymentDetails from "./components/paymentDetails";
import Modal from "../../components/Modal";
import OtpActionSection from "../../components/OtpAction";
import { useAcceptTnc, useEvaluateProduct, useGetDraftData, useSaticData, useUpdateDraft, useValidateDraft } from "../../lib/api";
import TermsContent from "../../components/AppTerms/TermsContent";
import Checkbox from "../../components/Checkbox";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import cogoToast from "cogo-toast";

const ReviewOrder = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [otpmodal, setOtpModal] = useState(false);
  const [openTncModal, setOpenTncModal] = useState(false);
  const [tncChecked, setTncChecked] = useState(false);
  const [discountCoupData, setDisCountCoupData] = useState({});
  const [totalPremium, setTotalPremium] = useState(0)
  const { data: tnCData, getStaticData = () => {} } = useSaticData({
    product_code: "aus-travel-insurance",
    data_type: "TNC",
  });
  const { data: draftData = {}, dataLoading: draftDataLoading, getTravelDraftData } = useGetDraftData();
  const { data: packageListData = {}, dataLoading: pkgListDataLoading, getPackageList = () => {} } =
  useEvaluateProduct();

  const draftInitiated =
  draftData?.status === 'QUOTE_INITIATED' ||
  draftData?.status === 'PAYMENT_ATTEMPTED';


  useEffect(() => {
    getStaticData();
    searchParams.get("draft_id") &&
      getTravelDraftData(searchParams.get("draft_id"));
  }, []);

  useEffect(() => {
    if (draftData?.payload?.package_request){
      getPackageList("aus-travel-insurance", {...draftData?.payload?.package_request, "request_type": "web",})
    }
  }, [draftData.payload])

  const isFamilyPackage = () => {
    return draftData?.payload?.package_request?.product_type === "family-A" ||
      draftData?.payload?.package_request?.product_type === "family-B"
      ? true
      : false;
  };

  const getFooter = () => {
    return (
      <FooterTnCCtn>
        <FooterCheckCtn>
          <Checkbox
            name="tnc-chekcbox"
            id="tnc-chekcbox"
            onClick={() => setTncChecked(!tncChecked)}
            checked={tncChecked}
            type="checkbox"
          />
          <FooterText>
            Saya telah membaca dan menyetujui syarat & ketentuan
          </FooterText>
        </FooterCheckCtn>
        <TnCAcceptBtn
          id="set-tnc-btn"
          loading={acceptTnCCallLoading}
          onClick={handleAcceptTnc}
          type="secondary"
          disabled={!tncChecked}
        >
          Bayar
        </TnCAcceptBtn>
      </FooterTnCCtn>
    );
  };

  const { updateDraft, isLoading: updateDraftLoading } = useUpdateDraft({
    draftId: searchParams.get("draft_id"),
    step: 2,
    onSuccess: () => {
      validateDraft()
      if (searchParams.get("draft_id")) {
        getTravelDraftData(searchParams.get("draft_id"))
      }
    },
    onFailure: (e) => console.error(e),
  });

  const {
    validateDraft,
    isLoading: validateDraftLoading
  } = useValidateDraft({
    productCode: 'aus-travel-insurance',
    draftId: searchParams.get("draft_id"),
    onSuccess: () => {},
    onFailure: (e) => {
      cogoToast.error(
        e?.response?.data?.error_details?.errors_v2.message.id ||
          e?.response?.data["validation_error"][0] ||
          "Unable to Proceed, Harap validasi formulir",
        { position: "top-right" }
      );
    }
  })

  const handleSubmit = useCallback(() => {
    navigate({
      pathname: "/payment/payment-mode",
      search: createSearchParams({
        draft_id: searchParams.get("draft_id"),
        product: "travel",
      }).toString(),
    });
  }, [searchParams.get("draft_id")]);

  const { acceptTnc, isLoading: acceptTnCCallLoading } = useAcceptTnc({
    onSuccess: () => {
      handleSubmit()
    },
    onFailure: (error) => {
      cogoToast.error(error?.response?.data?.error || 'Unable to accept TnC',{ position: "top-right" });
    }
  })

  const handleAcceptTnc = useCallback(() => {
    acceptTnc(draftData?.product_code, searchParams.get("draft_id"), tnCData?.ref_id)
  }, [acceptTnc, draftData?.product_code, searchParams.get("draft_id"), tnCData?.ref_id])

  const handleCheckSubmit = () => {
    (!draftInitiated && draftData.payload.phone_no_authenticated !== 'true') ?  setOtpModal(true) : setOpenTncModal(true)
    if (draftInitiated) {
      navigate({
        pathname: "/payment/payment-mode",
        search: createSearchParams({
          draft_id: searchParams.get("draft_id"),
          product: "travel",
        }).toString(),
      });
    }
    setTncChecked(false)
  }

  const handleDraftUpdate = () => {
    const draftPayload = {
      product_code: draftData?.product_code,
      package_code: draftData?.package_code,
      data: {
        ...draftData.payload,
        phone_no_authenticated: 'true',
      },
    };

    updateDraft(draftPayload);
  }

  return (
    <ReviewOrderWrapper>
      <TravelHeader />
      <Timeline header="Konfirmasi" activeStep={4} />
      <HeaderText>Konfirmasi Data & Dokumen Anda</HeaderText>
      <SecondHeaderText>
        Silakan cek kembali semua form Anda dan pastikan semua informasi sudah
        benar
      </SecondHeaderText>
      <ReviewOrderCtn>
        {(draftDataLoading || pkgListDataLoading) ? <div style={{height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <BounceLoader color="#b33cfa" size={90} />
            </div> : 
          draftData?.payload && <ReviewOrderdDetailsCtn>
            <div>
              <TravelDetails
                benefitData={Benefitdata(draftData?.payload)}
                benefitPackageData={BenefitPackageData(packageListData, draftData?.payload)}
              />
              <TravelHolderDetails>
                <CardTitle>Wisatawan 1 (Pemegang Polis) </CardTitle>
                {Object.keys(holderCardProcessor(draftData?.payload))?.map(
                  (draftPayload) => {
                    return (
                      <CardDeatilCtn>
                        <CardDeatilTitle>{draftPayload}</CardDeatilTitle>
                        <PolicyHolderInfoCard
                          data={
                            holderCardProcessor(draftData?.payload)[
                              draftPayload
                            ]
                          }
                        />
                      </CardDeatilCtn>
                    );
                  }
                )}
              </TravelHolderDetails>
              {draftData?.payload &&
                draftData?.payload.insured_data &&
                draftData?.payload.insured_data.length > 0 &&
                draftData?.payload.insured_data.map((insured, idx) => {
                  return (
                    <TravelHolderDetails>
                      <CardTitle>Wisatawan {idx + 2}</CardTitle>
                      {Object.keys(
                        isFamilyPackage()
                          ? insuredCardProcessor(insured)
                          : holderCardProcessor(insured)
                      )?.map((draftPayload) => {
                        return (
                          <CardDeatilCtn>
                            <CardDeatilTitle>{draftPayload}</CardDeatilTitle>
                            <PolicyHolderInfoCard
                              data={
                                isFamilyPackage()
                                  ? insuredCardProcessor(insured)[draftPayload]
                                  : holderCardProcessor(insured)[draftPayload]
                              }
                            />
                          </CardDeatilCtn>
                        );
                      })}
                    </TravelHolderDetails>
                  );
                })}
            </div>
            <PaymentDetails draftData={draftData} setDisCountCoupData={setDisCountCoupData} updateDraft={updateDraft} setTotalPremium={setTotalPremium}/>
          </ReviewOrderdDetailsCtn>
        }
      </ReviewOrderCtn>
      <TravelFooter
        premium={totalPremium}
        btnText="Pembayaran"
        onSubmit={() => handleCheckSubmit()}
        leftText='Total Biaya'
        loading={updateDraftLoading || validateDraftLoading}
        disabled={draftDataLoading}
      />
      <Modal
        title={otpmodal ? "Konfirmasi OTP" : "Informasi"}
        show={otpmodal || openTncModal}
        onClose={() => {
          setOtpModal(false);
          setOpenTncModal(false);
        }}
        maxWidth="640px"
        showFooter
        footer={openTncModal && getFooter()}
      >
        {otpmodal && (
          <OtpActionSection
            otpInfo={{
              phoneNo: draftData?.payload?.phone_no || '7860238300',
            }}
            onGenerateOpId={() => {}}
            onVerifyOTPSuccess={() => {
              setOtpModal(false);
              setOpenTncModal(true);
              handleDraftUpdate()
            }}
            onResetOtpForm={() => {}}
          />
        )}

        {openTncModal && (
          <PoliciesInfoCtn>
            <TermsContent termsData={tnCData} getter={(x) => x?.data} draftData={draftData}/>
          </PoliciesInfoCtn>
        )}
      </Modal>
    </ReviewOrderWrapper>
  );
};

export default ReviewOrder;
