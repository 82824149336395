import styled from "styled-components";

export const SelectionWrapper = styled.div``
export const SelectInputCtn = styled.div`
    display: grid;
    grid-template-columns: 90% 10%;
    border-bottom: 2px solid #f5f5f5;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0px;   
    z-index: 2;
    background: #ffffff;
`
export const SelectInput = styled.input`
    outline: none;
    height: 50px;
    padding: 0px 0px 0px 30px;
    border: none;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
`
export const Countries = styled.div`
    height: 40px;
    padding: 5px 30px;
    gap: 8px;
    border-bottom: 2px solid #f5f5f5;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background-color: ${props => props.isSelected ? '#FAFA96' : '#ffffff'};

    &:hover {
        background-color: #FAFA96;
    }
`
export const NoDestinationCtn = styled.div`
    margin: 30px auto;
    text-align: center;
`
export const SelectCountryCtn = styled.div`
    display: grid;
    grid-template-columns: 90% 10%;
    border-bottom: 2px solid #f5f5f5;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0px;   
    z-index: 2;
    background: #ffffff;
    height: 40px;
    padding: 5px 0px;
    justify-content: space-between;
`
export const SelectCountry = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`
export const Placeholder = styled.div`
    font-size: 10px;
    font-family: Mulish;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    padding-left: 30px;
    color: #999999;
`
export const SelectCountryName = styled.div`
    font-size: 14px;
    font-family: Mulish;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding-left: 30px;
`
export const FooterBtnWarpper = styled.div`
    padding: 20px 24px 20px 24px;
    gap: 0px;
    border-top: 2px solid #f5f5f5;
    position: sticky;
    bottom: 0px;
    background: #ffffff;

`
export const FooterBtn = styled.button`
    width: 100%;
    height: 40px;
    padding: 12px 16px 12px 16px;
    gap: 8px;
    border-radius: 12px;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 800;
    line-height: 16px;
    text-align: center;
    border: none;
    background: ${props => props.disabled ? '#EEEEEE' : '#FAF000'};
    color: ${props => props.disabled ? '#CCCCCC' : '#333333'};
`