import React, { useCallback, useRef, useState } from "react";
import {
  InputTextCtn,
  PromptError,
  PromptErrorMsg,
  UploadIconContainer,
  UploadImageCodeContainer,
  UploadImageLabel,
  UploadItemV2,
  UploadTextLabel,
} from "./elements";
import { ErrorMessage, useField } from "formik";
import { ImageUrl } from "../../Images";
import { useIntl } from "react-intl";
import { generateSignedUrl, uploadToSignedUrl } from "../../lib/draft";
import cogoToast from "cogo-toast";

const ACCEPTED_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  "video/mp4",
  "application/msword",
];

const IMAGE_LIMIT_MBS = 5;
const VIDEO_LIMIT_MBS = 25;
const LOWER_LIMIT_MBS = 0.01;

export default function Upload({
  label = "",
  onHandleChange = () => {},
  inputValue = "",
  top = "5px",
  placeholder = "",
  showLabel = true,
  ...props
}) {
  const [field, meta, helpers] = useField(props.name);
  const [value, setValue] = useState("");
  const uploadRef = useRef(null);
  const intl = useIntl();

  const limitSizeOnType = (type, size) => {
    const lowerLimit = size / 1024 / 1024 < LOWER_LIMIT_MBS;
    let upperLimit = size / 1024 / 1024 > IMAGE_LIMIT_MBS;

    if (type.includes("video")) {
      upperLimit = size / 1024 / 1024 > VIDEO_LIMIT_MBS;
    }

    return { lowerLimit, upperLimit };
  };

  const handleUploadFile = useCallback(
    async (data) => {
      const fileValue = data.target.files || {};
      const file = fileValue[0];
      if (file) {
        const { size, type } = file;
        const { lowerLimit, upperLimit } = limitSizeOnType(type, size);
        if (lowerLimit) {
          helpers.setError(
            intl.formatMessage(
              {
                id: "Ukuran file terlalu kecil, min {lowerlimit}Kb",
                defaultMessage: "Ukuran file terlalu kecil, min {lowerlimit}Kb",
              },
              {
                lowerLimit: LOWER_LIMIT_MBS * 1000,
              }
            )
          );
          return;
        }

        if (upperLimit) {
          helpers.setError(
            intl.formatMessage(
              {
                id: "Ukuran file terlalu besar, maks {upperLimit}Mb",
                defaultMessage:
                  "Ukuran file terlalu besar, maks {upperLimit}Mb",
              },
              {
                upperLimit: type.includes("video")
                  ? VIDEO_LIMIT_MBS
                  : IMAGE_LIMIT_MBS,
              }
            )
          );
          return;
        }
        try {
          const res = await generateSignedUrl({
            content_type: file.type,
            file_name: file.name,
          })
          uploadToSignedUrl(res.data.signed_url, file).then((response) => {
            console.log('res =>', response)
          })
          props.setFormValues({
            ...props.formValues,
            attachments: res?.data?.gcs_url,
          });
          setValue(file?.name)
        } catch {
          cogoToast.error("Error Uploading file", { position: "top-right" });
        }
      }
    },
    [props.formValues]
  );

  const handleDocumentClick = () => {
    if (uploadRef) {
      uploadRef?.current.click();
    }
  };

  return (
    <InputTextCtn>
      {field.value ? (
        <UploadImageCodeContainer error={meta.touched && !!meta.error}>
          <UploadItemV2
            ref={uploadRef}
            id={`${props.name}~re-upload`}
            // {...field}
            {...props}
            value={undefined}
            onChange={handleUploadFile}
            type="file"
            accept={ACCEPTED_TYPES.join(",")}
          />
          <UploadTextLabel onClick={handleDocumentClick}>
            {value}
          </UploadTextLabel>
          <UploadIconContainer>
            <img
              src={ImageUrl.Upload}
              width="24px"
              height="24px"
              alt="upload-icon"
            />
          </UploadIconContainer>
        </UploadImageCodeContainer>
      ) : (
        <UploadImageCodeContainer error={meta.touched && !!meta.error}>
          <UploadItemV2
            id={`${props.name}~upload`}
            //  {...field}
            {...props}
            value={undefined}
            onChange={handleUploadFile}
            type="file"
            accept={ACCEPTED_TYPES.join(",")}
          />
          <UploadImageLabel htmlFor={`${props.name}~upload`}>
            {label}
          </UploadImageLabel>
          <UploadIconContainer>
            <img
              src={ImageUrl.Upload}
              width="24px"
              height="24px"
              alt="upload-icon"
            />
          </UploadIconContainer>
        </UploadImageCodeContainer>
      )}
      <ErrorMessage
        name={props.name}
        render={(msg) => (
          <PromptError>
            <PromptErrorMsg>
              <div>{msg}</div>
            </PromptErrorMsg>
          </PromptError>
        )}
      />
    </InputTextCtn>
  );
}
