import React, { useMemo } from "react";
import {
  ContentWrapper,
  PaymentTopImageCtn,
  PaymnetSucessFullWarpper,
  MiddleIconCtn,
  DetailsWrapper,
  DetailsTab,
  DetailsTabValue,
  DetailsTabHeader,
  PageHeader,
  DetailsText,
  PaymentBoldText,
  DocumentBtn,
  DetailsTabCtnExpired,
} from "../elements";
import { ImageUrl } from "../../../Images";
import { FormattedMessage } from "react-intl";
import convertToIDR from "../../../lib/utility/convertToIDR";
import { useNavigate } from "react-router-dom";

const PaymentPending = (props) => {
  const navigate = useNavigate();

  const detailCard = useMemo(() => {
    return [
      {
        icon: ImageUrl.NotesBlue,
        title: "Status Transaksi",
        value: props.data?.payment_status || '-',
      },
      {
        icon: ImageUrl.BagBlue,
        title: "Total Premi",
        value: convertToIDR(props.data?.amount || ''),
      },
    ]
  }, [props.data]);

  const goHome = () => {
    navigate({
      pathname: "/",
    });
  };

  return (
    <PaymnetSucessFullWarpper>
      <PaymentTopImageCtn banner={ImageUrl.PayExpired}/>
      <ContentWrapper>
        <MiddleIconCtn>
          <img src={ImageUrl.PaymentExpired} height="128px" width="128px" alt='success-icon'/>
        </MiddleIconCtn>
        <DetailsWrapper>
          <PageHeader color="#0096FA">Waktu Pembayaran Berakhir</PageHeader>
          {props.data?.text && props.data?.text[0] && <DetailsText>
            <FormattedMessage
                id={props.data?.text[0]}
                defaultMessage={props.data?.text[0]}
                values={{
                    b: (chunks) => <PaymentBoldText>{chunks}</PaymentBoldText>,
                }}
            />
          </DetailsText>}
            <DocumentBtn
                id="send-otp-btn"
                loading={false}
                onClick={() => goHome()}
                type="secondary"
            >
                Beli Asuransi Sekarang
            </DocumentBtn>
          <DetailsTabCtnExpired>
            {detailCard.map((card) => {
              return (
                <DetailsTab>
                  <img src={card.icon} width="32px" height="32px" alt="icon"/>
                  <DetailsTabHeader>{card.title}</DetailsTabHeader>
                  <DetailsTabValue>{card.value}</DetailsTabValue>
                </DetailsTab>
              );
            })}
          </DetailsTabCtnExpired>
        </DetailsWrapper>
      </ContentWrapper>
    </PaymnetSucessFullWarpper>
  );
};

export default PaymentPending;
