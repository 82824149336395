import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  FormItem,
  HolderFormWrapper,
  HolderTitle,
  Title,
  GridContainer,
} from "../elements";
import * as Yup from "yup";
import { Formik } from "formik";
import FormHook from "../../../components/FormComponents/FormHook";
import TextInput from "../../../components/FormComponents/InputText";
import TextInputV2 from "../../../components/FormComponents/InputTextV2";
import BooleanValue from "../../../components/FormComponents/Booleanvalue";
import { format, isAfter, parse, subDays, subMonths } from "date-fns";
import Modal from "../../../components/Modal";
import DateOfBirth from "../../../components/DateOfBirth";
import extractPhoneNo from "../../../lib/utility/extractPhoneNo";
import { get } from "lodash";
import { useGetKota, useGetProvice } from "../../../lib/api";
import SelectCountry from "../../../components/FormComponents/SelectCountry";
import SelectDomesticDestination from "../../../components/FormComponents/SelectDomesticDestination";
import { BounceLoader } from "react-spinners";

const INTIIAL_VALUES = {
  name: "",
  email: "",
  phone_no: "",
  citizenship: "",
  ktp: "",
  passport_no: "",
  kitas_kitap: "",
  gender: "",
  dob: "",
  policy_holder_address: {
    province_code: "",
    province_name: "",
    city_code: "",
    city_name: "",
    address: "",
  },
};

const DOMESTIC_INDONESIAN_SCHEMA = {
  name: Yup.string()
    .required("Nama tidak boleh kosong")
    .max(60, "Nama maksimal 60 karakter")
    .nullable(),
  citizenship: Yup.string()
    .required("Kewarganegaraan tidak boleh kosong")
    .nullable(),
  email: Yup.string()
    .email("Email Tidak Valid")
    .required("Email tidak boleh kosong")
    .max(200, "Email maksimal 200 karakter")
    .nullable(),
  phone_no: Yup.string().required("Nomor Ponsel tidak boleh kosong"),
  ktp: Yup.string()
    .min(16, "KTP minimal 16 karakter")
    .max(16, "KTP maksimal 16 karakter")
    .required("KTP tidak boleh kosong"),
  gender: Yup.string().required("Jenis Kelamin tidak boleh kosong"),
  dob: Yup.string().required('Tanggal lahir tidak boleh kosong')
    .test(
      'is-before-today',
      () => 'Tanggal lahir tidak valid',
      (value) => {
        // const prevDay = subDays(new Date(), 1)
        const dateVal = parse(value, 'yyyy-MM-dd', new Date())
        // return true
        return isAfter(new Date(), dateVal)
      })
      .test(
        'is-more-than-70-years',
        () => 'Umur tertanggung lebih dari 69 tahun.',
        (value) => {
          const dateVal = parse(value, 'yyyy-MM-dd', new Date())
          const diffTime = Math.abs(new Date() - dateVal);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 25567) {
            return false
          }
          return true
        }),
  policy_holder_address: Yup.object({
    province_code: Yup.string().required("Provinsi tidak boleh kosong"),
    city_code: Yup.string().required("Kota tidak boleh kosong"),
    address: Yup.string()
      .required("Alamat tidak boleh kosong")
      .max(200, "Alamat maksimal 200 karakter"),
  }),
};

const DOMESTIC_NON_INDONESIAN_SCHEMA = {
  name: Yup.string()
    .required("Nama tidak boleh kosong")
    .max(60, "Nama maksimal 60 karakter")
    .nullable(),
  citizenship: Yup.string()
    .required("Kewarganegaraan tidak boleh kosong")
    .nullable(),
  email: Yup.string()
    .email("Email Tidak Valid")
    .required("Email tidak boleh kosong")
    .max(200, "Email maksimal 200 karakter")
    .nullable(),
  phone_no: Yup.string().required("Nomor Ponsel tidak boleh kosong"),
  kitas_kitap: Yup.string()
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "KITAS/KITAP hanya boleh berisi huruf, angka, dan tanda hubung"
    )
    .min(1, "KITAS/KITAP minimal 1 karakter")
    .max(12, "KITAS/KITAP maksimal 12 karakter")
    .required("KITAS/KITAP tidak boleh kosong"),
  gender: Yup.string().required("Jenis Kelamin tidak boleh kosong"),
  dob: Yup.string().required('Tanggal lahir tidak boleh kosong')
    .test(
      'is-before-today',
      () => 'Tanggal lahir tidak valid',
      (value) => {
        // const prevDay = subDays(new Date(), 1)
        const dateVal = parse(value, 'yyyy-MM-dd', new Date())
        // return true
        return isAfter(new Date(), dateVal)
      })
      .test(
        'is-more-than-70-years',
        () => 'Umur tertanggung lebih dari 69 tahun.',
        (value) => {
          const dateVal = parse(value, 'yyyy-MM-dd', new Date())
          const diffTime = Math.abs(new Date() - dateVal);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 25567) {
            return false
          }
          return true
        }),
  policy_holder_address: Yup.object({
    province_code: Yup.string().required("Provinsi tidak boleh kosong"),
    city_code: Yup.string().required("Kota tidak boleh kosong"),
    address: Yup.string()
      .required("Alamat tidak boleh kosong")
      .max(200, "Alamat maksimal 200 karakter"),
  }),
};

const INTERNATIONAL_SCHEMA = {
  name: Yup.string()
    .required("Nama tidak boleh kosong")
    .max(60, "Nama maksimal 60 karakter")
    .nullable(),
  email: Yup.string()
    .email("Email Tidak Valid")
    .required("Email tidak boleh kosong")
    .max(200, "Email maksimal 200 karakter")
    .nullable(),
  phone_no: Yup.string().required("Nomor Ponsel tidak boleh kosong"),
  passport_no: Yup.string()
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "Paspor hanya boleh berisi huruf, angka, dan tanda hubung"
    )
    .max(9, "Paspor maksimal 9 karakter")
    .required("Paspor tidak boleh kosong"),
  gender: Yup.string().required("Jenis Kelamin tidak boleh kosong"),
  dob: Yup.string().required('Tanggal lahir tidak boleh kosong')
    .test(
      'is-before-today',
      () => 'Tanggal lahir tidak valid',
      (value) => {
        // const prevDay = subDays(new Date(), 1)
        const dateVal = parse(value, 'yyyy-MM-dd', new Date())
        // return true
        return isAfter(new Date(), dateVal)
      })
      .test(
        'is-more-than-70-years',
        () => 'Umur tertanggung lebih dari 69 tahun.',
        (value) => {
          const dateVal = parse(value, 'yyyy-MM-dd', new Date())
          const diffTime = Math.abs(new Date() - dateVal);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 25567) {
            return false
          }
          return true
        }),
  policy_holder_address: Yup.object({
    province_code: Yup.string().required("Provinsi tidak boleh kosong"),
    city_code: Yup.string().required("Kota tidak boleh kosong"),
    address: Yup.string()
      .required("Alamat tidak boleh kosong")
      .max(200, "Alamat maksimal 200 karakter"),
  }),
};

const HolderForm = ({
  isInternationalTravel,
  payload,
  onFormSubmit = () => {},
  submitForm,
  setHolderData,
  setSubmitArray,
  submitArray,
  draftPayload = {},
  setHolderGender = () => {},
}) => {
  const [formValues, setFormValues] = useState(INTIIAL_VALUES);
  const [formSchema, setFormSchema] = useState(
    isInternationalTravel ? INTERNATIONAL_SCHEMA : DOMESTIC_INDONESIAN_SCHEMA
  );
  const [showDOBModal, setShowDOBModal] = useState(false);
  const formRef = useRef();
  const [showProvice, setShowProvince] = useState(false);
  const [showKota, setShowKota] = useState(false);
  const { data: provinceList, getProvinceList } = useGetProvice();
  const {
    data: kotaList,
    dataLoading: kotaDataLoading,
    getKotaList,
  } = useGetKota();

  useEffect(() => {
    getProvinceList();
  }, []);

  useEffect(() => {
    let holderValues = { ...draftPayload };
    delete holderValues["insured_data"];
    if (draftPayload?.phone_no) {
      holderValues["phone_no"] = extractPhoneNo(draftPayload?.phone_no);
    }
    setFormValues((prevVal) => ({
      ...prevVal,
      ...holderValues,
    }));
  }, [draftPayload]);

  const handleChange = (values) => {
    setFormValues(values);
  };

  const dummySubmit = (_values, _formikbag) => {
    // dummy handle submit func
  };

  useEffect(() => {
    setFormSchema(
      isInternationalTravel ? INTERNATIONAL_SCHEMA : DOMESTIC_INDONESIAN_SCHEMA
    );
  }, [isInternationalTravel]);

  useEffect(() => {
    if (
      formValues.citizenship &&
      formValues.citizenship === "Indonesian" &&
      !isInternationalTravel
    ) {
      setFormValues({ ...formValues, kitas_kitap: "" });
      setFormSchema(DOMESTIC_INDONESIAN_SCHEMA);
    } else if (
      formValues.citizenship &&
      formValues.citizenship === "Foreigner" &&
      !isInternationalTravel
    ) {
      setFormValues({ ...formValues, ktp: "" });
      setFormSchema(DOMESTIC_NON_INDONESIAN_SCHEMA);
    }
  }, [formValues.citizenship]);

  useEffect(() => {
    setHolderGender(formValues.gender);
  }, [formValues.gender]);

  useEffect(() => {
    if (submitForm) {
      formRef.current.submitForm().then(() => {
        Yup.object(formSchema)
          .validate(formRef.current.values)
          .catch((err) => {
            console.error(err);
          })
          .then((values) => {
            if (values) {
              setHolderData(values);
              submitArray[0] = true;
              setSubmitArray(submitArray);
            }
          });
      });
    }
  }, [submitForm]);

  const handleSelectProvince = (formValues, val, setFormValues) => {
    const filteredList = provinceList.filter((i) => i.province_code === val);
    setFormValues({
      ...formValues,
      policy_holder_address: {
        ...formValues.policy_holder_address,
        province_code: val,
        province_name: filteredList[0]?.province_name || "",
        city_code: "",
      },
    });
    getKotaList(val);
    setShowProvince(false);
    setShowKota(true);
  };
  const handleSelectKota = (formValues, val, setFormValues) => {
    const filteredList = kotaList.filter((i) => i.city_code === val);
    setFormValues({
      ...formValues,
      policy_holder_address: {
        ...formValues.policy_holder_address,
        city_code: val,
        city_name: filteredList[0]?.city_name || "",
      },
    });
    setShowKota(false);
  };

  const handleEditFromCountry = () => {
    setShowProvince(true);
    setShowKota(false);
  };

  return (
    <HolderFormWrapper>
      <HolderTitle>Wisatawan 1 (Pemegang Polis) </HolderTitle>
      <Formik
        onSubmit={dummySubmit}
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object(formSchema)}
        innerRef={formRef}
      >
        {(formProps) => (
          <>
            <FormHook values={formProps.values} onChange={handleChange} />
            <Title>Detail Personal</Title>
            <TextInputV2
              label="Nama Lengkap"
              name="name"
              required
              showIcon={false}
              readonly={false}
            />
            <GridContainer>
              <FormItem>
                <BooleanValue
                  name="gender"
                  optionsList={[
                    {
                      display_name: "Laki-laki",
                      value: "male",
                    },
                    {
                      display_name: "Perempuan",
                      value: "female",
                    },
                  ]}
                  getter={(val) => val.value}
                  renderer={(val) => val.display_name}
                />
              </FormItem>
              <TextInput
                name="dob"
                label="Tanggal Lahir"
                inputValue={
                  formProps.values.dob
                    ? format(formProps.values.dob, "dd/MM/yyyy")
                    : ""
                }
                icontype="date"
                onClick={() => setShowDOBModal(true)}
              />
            </GridContainer>
            <Title>Detail Identitas</Title>
            {!isInternationalTravel ? (
              <GridContainer>
                <BooleanValue
                  name="citizenship"
                  optionsList={[
                    {
                      display_name: "WNI",
                      value: "Indonesian",
                    },
                    {
                      display_name: "WNA",
                      value: "Foreigner",
                    },
                  ]}
                  getter={(val) => val.value}
                  renderer={(val) => val.display_name}
                />
                {formValues.citizenship === "Foreigner" ? (
                  <TextInputV2
                    label="Nomor KITAS/KITAP"
                    name="kitas_kitap"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                ) : (
                  <TextInputV2
                    label="Nomor KTP"
                    name="ktp"
                    type="number"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                )}
              </GridContainer>
            ) : (
              <TextInputV2
                label="Nomor Paspor"
                name="passport_no"
                required
                showIcon={false}
                readonly={false}
              />
            )}
            <Title>Detail Kontak</Title>
            <GridContainer>
              <TextInputV2
                label="Nomor Ponsel"
                name="phone_no"
                required
                showIcon={false}
                readonly={false}
              />
              <TextInputV2
                label="Email"
                name="email"
                required
                showIcon={false}
                readonly={false}
              />
            </GridContainer>
            <Title>Detail Alamat</Title>
            <GridContainer>
              <TextInput
                name="policy_holder_address.province_code"
                label="Provinsi"
                inputValue={
                  formProps?.values?.policy_holder_address?.province_name
                }
                onClick={() => setShowProvince(true)}
                icontype="text"
              />
              <TextInput
                name="policy_holder_address.city_code"
                label="Kota"
                inputValue={formProps?.values?.policy_holder_address?.city_name}
                onClick={() => setShowKota(true)}
                icontype="text"
              />
            </GridContainer>
            <TextInputV2
              name="policy_holder_address.address"
              label="Alamat Lengkap"
              placeholder="Alamat Lengkap, Cth: Komplek Abadi 1 no. 2, Senayan, Jakarta Pusat, 11980"
              required
              showIcon={false}
              readonly={false}
            />
            <Modal
              title={"Tanggal Lahir"}
              show={showDOBModal}
              onClose={() => setShowDOBModal(false)}
              maxWidth="500px"
            >
              <div>
                <DateOfBirth
                  name="dob"
                  label="Tanggal Lahir"
                  minDate={subMonths(
                    new Date(payload?.holder?.package_request?.trip_start_date),
                    formValues.relationship === "child" ? 228 : 828
                  )}
                  maxDate={subDays(
                    subMonths(
                      new Date(
                        payload?.holder?.package_request?.trip_start_date
                      ),
                      formValues.relationship === "child" ||
                        formValues.relationship === "sibling"
                        ? 2
                        : 192
                    ),
                    1
                  )}
                  setFormValues={formProps.setValues}
                  formValues={formProps.values}
                  setShowDOBModal={setShowDOBModal}
                />
              </div>
            </Modal>

            <Modal
              title={showProvice ? "Provinsi" : "Kota"}
              show={showProvice || showKota}
              onClose={() => {
                setShowProvince(false);
                setShowKota(false);
              }}
              maxWidth="500px"
            >
              {showProvice ? (
                <SelectCountry
                  name="policy_holder_address.province_code"
                  label="Provinsi"
                  onSelect={(val) =>
                    handleSelectProvince(
                      formProps.values,
                      val,
                      formProps.setValues
                    )
                  }
                  countryListOptions={provinceList.length && provinceList}
                  getter={(x) => x.province_code}
                  renderer={(x) => x.province_name}
                  placeholder="Cari"
                />
              ) : kotaDataLoading ? (
                <div
                  style={{
                    height: "400px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BounceLoader color="#b33cfa" size={90} />
                </div>
              ) : (
                <SelectDomesticDestination
                  name="policy_holder_address.city_code"
                  label="Kota"
                  onSubmit={(val) =>
                    handleSelectKota(formProps.values, val, formProps.setValues)
                  }
                  countryListOptions={kotaList.length && kotaList}
                  getter={(x) => x.city_code}
                  renderer={(x) => x.city_name}
                  placeholder="Cari"
                  handleEditFromCountry={handleEditFromCountry}
                  selectCountry={
                    formProps.values.policy_holder_address.province_code
                  }
                  setShowSchengen={() => {}}
                  hideOption={""}
                />
              )}
            </Modal>
          </>
        )}
      </Formik>
    </HolderFormWrapper>
  );
};

export default HolderForm;
