import React from "react";
import { TimelineWrapper, TiemlineCtn, Header, TimelineStepsWarpper, TimelineStepsCtn, TimelineStepsBar, StepsWrapper, StepsText } from './elements'
import { ImageUrl } from "../../Images";

const timelines = [
    {
        step: 1,
        title: 'Detail Perjalanan'
    },
    {
        step: 2,
        title: 'Pilih Paket'
    },
    {
        step: 3,
        title: 'Isi Data'
    },
    {
        step: 4,
        title: 'Konfirmasi'
    }, 
    {
        step: 5,
        title: 'Pembayaran'
    },
]

const Timeline = (props) => {
    const {header = '', activeStep = 1, } = props

    return (
        <TimelineWrapper>
            <TiemlineCtn>
                <Header>{activeStep}. {header}</Header>
                <TimelineStepsWarpper>
                    {
                        timelines.map((step, ind) => {
                            return (
                                <StepsWrapper>
                                    {
                                        ind >= 1 && <TimelineStepsBar curr={activeStep === ind + 1} inComing={ind + 1 > activeStep} prevStep={activeStep > ind + 1}/>
                                    }
                                    {
                                        activeStep > ind + 1 ? <img
                                        src={ImageUrl.BlueCheck}
                                        width="20px"
                                        height="20px"
                                        alt="check-icon"
                                    /> : <TimelineStepsCtn curr={activeStep === ind + 1} inComing={ind + 1 > activeStep}>{activeStep === ind + 1 ? 'O' : ind + 1}</TimelineStepsCtn>
                                    }
                                    <StepsText curr={activeStep === ind + 1} inComing={ind + 1 > activeStep} prevStep={activeStep > ind + 1}>{step.title}</StepsText>
                                </StepsWrapper>
                            )
                        })
                    }
                </TimelineStepsWarpper>
            </TiemlineCtn>
        </TimelineWrapper>
    )
}

export default Timeline