import styled from "styled-components";
import { ImageUrl } from "../../Images";

export const DatePickerContainer = styled.div`
  width: 100%;
  border-radius: 24px;
  background-color: #FFF;

  .datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    // padding: 24px;
    border-radius: 24px 24px 0 0;
  }

  .datepicker-header h1 {
    font-size: 16px;
  }

  .datepicker-subheader {
    // padding: 24px;
  }
  .datepicker-subheader2 {
    padding: 12px 24px;
  }

  .datepicker-subheader2 h2 {
    font-size: 16px;
  }
  .datepicker-subheader h2 {
    font-size: 16px;
  }

  .close-btn {
    cursor: pointer;
  }

  .react-calendar {
    // padding: 24px;
    height: ${({ currentView }) => (currentView === 'month' ? '370px' : '300px')};
    transition: height 0.5s ease;
  }

  .react-calendar__month-view {
    min-height: 320px;
    width: 100%;
    height: 100%;
  }

  .react-calendar__navigation {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; 
    border-top: 2px solid #F5F5F5;
    border-bottom: 2px solid #F5F5F5;
    padding: 12px 0px 12px 0px;
  }

  .react-calendar__viewContainer{
    margin-top: 20px;
  }

  .react-calendar__navigation__prev-button{
    margin-left: 20px;
  }
  .react-calendar__navigation__next-button{
    margin-right: 20px;
  }

  .react-calendar__decade-view__years, .react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    height: 200px;
    gap: 12px;
  }

  .react-calendar__navigation button {
    font-size: 16px !important;
    background-color: #FFF;
    border: none;
    cursor: pointer;
    color: #333333;
    font-weight: 800;
  }


  .react-calendar__month-view__weekdays {
    display: grid !important;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 12px;
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
  }


  .react-calendar__tile {
    margin: 0;
  }

  .react-calendar__tile {
    font-size: 16px;
    background: #FFF;
    border: none;
    border-radius: 12px;
    transition: 0.5s;
    cursor: pointer;
  }

  .react-calendar__tile:hover {
    background: #FAFA96;
  }
    
  .react-calendar__tile--active {
      background: #FAFA96;
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    height: 48px;
    width: 48px;
  }

  .react-calendar__tile:disabled,  .react-calendar__navigation button:disabled {
    pointer-events: none;
  }

  .react-calendar__month-view__weekdays__weekday--weekend:last-child, .sunday{
    color: red;
  }

  .sunday:disabled {
    color: #ccc;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }

  .startDate {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .startDate h6, .startDate h2{
    margin: 0;
  }

  .startDate button {
    width: 24px;
    height: 24px;
  }

  .startDate-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #FFF;
    border: none;
  }

  .startDate h6 {
    color: #999999;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

export const DatePickerWarningContainer = styled.div`
  display: flex;
  border-radius: 8px;
  margin: 10px 20px;
  gap: 10px;
`

export const DatePickerWarningText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #333;
`
export const DatePickerWarningImg = styled.img.attrs({ src: ImageUrl.BlueInfo, width: '24px', height: '24px' })`
`
export const DatePickerWarningImgCtn = styled.div`
  display: flex;
  align-items: center;
`