import React, { useRef, useState } from "react";
import * as Yup from "yup";
import {
  AnchorTag,
  AnchorTag2,
  CaptchaErrorMsg,
  CaptchaWrapper,
  DropDownList,
  DropDownListElement,
  FormItem,
  FormSubmitBtn,
  FormSubmitBtnCtn,
  GridItem,
  PorductItem,
  SupportFormCtn,
  SupportFormWarpper,
  Title,
  TncSpan,
  TncText,
  TncUnderLine,
} from "./elements";
import FormHook from "../../components/FormComponents/FormHook";
import TextInputV3 from "../../components/FormComponents/InputTextV3";
import { Formik } from "formik";
import TextInput from "../../components/FormComponents/InputText";
import TextArea from "../../components/FormComponents/TextArea";
import { FormattedMessage } from "react-intl";
import { useCustomerSupport } from "../../lib/api";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import SupportFormSend from "./formSend";
import ReCAPTCHA from "react-google-recaptcha";
import extractPhoneNo from "../../lib/utility/extractPhoneNo";

const siteKey = process.env.REACT_APP_SITE_KEY;

const INTIIAL_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  subject: "",
  comment: "",
};

const FORM_SCHEMA = {
  first_name: Yup.string()
  .required("Nama Depan tidak boleh kosong")
  .max(100, "Nama Depan maksimal 100 karakter")
  .nullable(),
  last_name: Yup.string()
  .required("Nama Belakang boleh kosong")
  .max(100, "Nama Belakang maksimal 100 karakter")
  .nullable(),
  phone: Yup.string()
    .required("Nomor Ponsel tidak boleh kosong")
    .min(10, "Nomor Ponsel miniaml 10 karakter")
    .max(20, "Nomor Ponsel maksimal 20 karakter")
    .nullable(),
  email: Yup.string()
    .required("Email tidak boleh kosong")
    .max(100, "Email maksimal 100 karakter")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Masukkan Email yang valid')
    .nullable(),
  subject: Yup.string()
  .required("Pilih layanan yang diinginkan")
  .nullable(),
  comment: Yup.string()
  .required("Pesan tidak boleh kosong")
  .max(1000, "Pesan maksimal 1000 karakter")
  .nullable(),
};

const productKey = [
  "Produk dan Layanan",
  "Klaim Asuransi",
  "Polis Asuransi",
  "Teknis dan Akses",
  "Feedback dan Saran",
  "Lainnya",
];

const SupportForm = () => {
  const [formValues, setFormValues] = useState(INTIIAL_VALUES);
  const [formSchema, setFormSchema] = useState(FORM_SCHEMA);
  const [productDropdown, setProductDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const recaptcha = useRef();

  const navigate = useNavigate();

  const handleChange = (values) => {
    setFormValues(values);
  };

  const dummySubmit = (_values, _formikbag) => {
    // dummy handle submit func
  };

  const handProductSelect = (val) => {
    setFormValues({
      ...formValues,
      subject: val,
    });
    setProductDropdown(false);
  };

  const handleChangeCaptcha = () => {
    const captchaValue = recaptcha.current.getValue();
    if (captchaValue) {
      setCaptchaError(false);
    } else {
      setCaptchaError(true);
    }
  };

  const handleSubmit = (formProps) => {
    const captchaValue = recaptcha.current.getValue();
    formProps.submitForm().then(() => {
      Yup.object(formSchema)
        .validate(formProps.values)
        .catch((err) => {
          console.error(err);
        })
        .then((values) => {
          if (!captchaValue) {
            setCaptchaError(true);
          } else {
            if (values) {
                const payload = {
                  data: {
                    response_token: captchaValue,
                    form_data: {
                      ticket: {
                        requester: {
                          name: `${values.first_name} ${values.last_name}`,
                          email: values.email,
                          phone: `+62-${extractPhoneNo(values.phone)}`,
                        },
                        comment: {
                          body: values.comment,
                        },
                        priority: "urgent",
                        subject: `${values.subject} - ${values.first_name} ${values.last_name} - Website`,
                      }
                    }
                  }
              };
              zendeskApiCall(payload)
            }
          }
        });
    });
  };

  const { zendeskApiCall, isLoading: zendeskApiLoading } = useCustomerSupport({
    onSuccess: () => {
      setShowModal(true)
      setFormValues(INTIIAL_VALUES)
    },
    onFailure: () => {
      cogoToast.error('Error',{ position: "top-right" });
    }
  })

  return (
    <>
      <Formik
        onSubmit={dummySubmit}
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object(formSchema)}
      >
        {(formProps) => (
          <SupportFormWarpper>
            <SupportFormCtn>
              <FormHook values={formProps.values} onChange={handleChange} />
              <GridItem>
                <FormItem>
                  <Title>Nama Depan</Title>
                  <TextInputV3
                    name="first_name"
                    label="Nama Depan"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                </FormItem>
                <FormItem>
                  <Title>Nama Belakang</Title>
                  <TextInputV3
                    name="last_name"
                    label="Nama Belakang"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                </FormItem>
              </GridItem>
              <GridItem>
                <FormItem>
                  <Title>Nomor Ponsel</Title>
                  <TextInputV3
                    name="phone"
                    label="123 456 789"
                    type="number"
                    required
                    showIcon
                    readonly={false}
                    prefix="+62"
                    icontype="phone"
                  />
                </FormItem>
                <FormItem>
                  <Title>Email</Title>
                  <TextInputV3
                    name="email"
                    type="email"
                    label="Tulis Email"
                    required
                    showIcon
                    readonly={false}
                    icontype="sms"
                  />
                </FormItem>
              </GridItem>
              <GridItem>
                <FormItem>
                  <PorductItem>
                    <Title>Jenis Produk</Title>
                    <TextInput
                      name="subject"
                      label=""
                      showLabel={false}
                      placeholder="Produk"
                      inputValue={formProps?.values?.subject}
                      onClick={() => setProductDropdown(!productDropdown)}
                      icontype="text"
                    />
                    {productDropdown && (
                      <DropDownList>
                        {productKey.map((productInfo) => {
                          return (
                            <DropDownListElement
                              onClick={() => handProductSelect(productInfo)}
                            >
                              {productInfo}
                            </DropDownListElement>
                          );
                        })}
                      </DropDownList>
                    )}
                  </PorductItem>
                </FormItem>
                <FormItem />
              </GridItem>
              <FormItem>
                <Title>Pesan</Title>
                <TextArea
                  name="comment"
                  label="Masukkan Pesan"
                  width="100%"
                  placeholder="Masukkan Pesan"
                  onChange={() => {}}
                />
              </FormItem>
              <TncText>
                <FormattedMessage
                  id="Privasi Data Kamu Terjamin. Pilih Kirim untuk Menyetujui Syarat dan Ketentuan serta Kebijakan Privasi Kami."
                  defaultMessage={
                    "Privasi Data Kamu Terjamin. Pilih <b>Kirim</b> untuk Menyetujui <tnc>Syarat dan Ketentuan</tnc> serta <u>Kebijakan Privasi</u> Kami."
                  }
                  values={{
                    b: (chunks) => <TncSpan>{chunks}</TncSpan>,
                    u: (chunks) => <AnchorTag2 href="/privacy-policy" target="_blank"><TncUnderLine>{chunks}</TncUnderLine></AnchorTag2>,
                    tnc: (chunks) => <AnchorTag2 href="/terms-condition" target="_blank"><TncUnderLine>{chunks}</TncUnderLine></AnchorTag2>,
                  }}
                />
              </TncText>
            </SupportFormCtn>
            <FormSubmitBtnCtn>
              <CaptchaWrapper>
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={siteKey}
                  onChange={handleChangeCaptcha}
                />
                {captchaError && (
                  <CaptchaErrorMsg>
                    <FormattedMessage
                      id="Captcha tidak boleh kosong"
                      defaultMessage="Captcha tidak boleh kosong"
                    />
                  </CaptchaErrorMsg>
                )}
              </CaptchaWrapper>
              <FormSubmitBtn
                onClick={() => handleSubmit(formProps)}
                id="travel-trip-detail-btn"
                loading={zendeskApiLoading}
                type="yellow"
              >
                Kirim
              </FormSubmitBtn>
            </FormSubmitBtnCtn>
          </SupportFormWarpper>
        )}
      </Formik>
      <Modal
        title={"Form Marketing"}
        show={showModal}
        onClose={() => setShowModal(false)}
        showHeader={false}
      >
        <SupportFormSend setShowModal={setShowModal} />
      </Modal>
    </>
  );
};

export default SupportForm;
