// import { useState } from "react";
// import default react-pdf entry
import { useState } from "react";
import { Document, pdfjs } from "react-pdf";

import { PDFPreviewContainer, PDFPreviewPage } from "./elements";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PDFPreview({ src, onClick }) {

  const [pdfPages, setPdfPages] = useState()

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setPdfPages(numPages);
  }

  return (
    <PDFPreviewContainer>
      <Document
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(pdfPages), (el, index) => (
          <PDFPreviewPage
            key={`page_${index + 1}`}
            onClick={handleClick}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </PDFPreviewContainer>
  );
}
