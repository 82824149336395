import React from "react";
import { AnchorTag, DetailsSectionBoldText, DetailsSectionCtn, DetailsSectionHeader, DetailsSectionImageCtn, DetailsSectionText } from "./elements";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";

const DetailsSection = () => {

    return (
        <DetailsSectionCtn>
            <DetailsSectionHeader>
                <FormattedMessage 
                    id='Jangan Ragu untuk Menghubungi TAP Insure'
                    defaultMessage='Jangan Ragu untuk Menghubungi TAP Insure'
                />
            </DetailsSectionHeader>
            <DetailsSectionText>
                <FormattedMessage 
                    id='Isi formulir di samping untuk mendapatkan perlindunganmu'
                    defaultMessage='Isi formulir di samping untuk mendapatkan perlindunganmu'
                />
            </DetailsSectionText>
            <img src={ImageUrl.CustomerSupport} width='100%' height='454px' alt='support'/>
            <DetailsSectionText>
                <FormattedMessage 
                    id='Untuk pengaduan & layanan klaim silakan menghubungi Customer Service kami'
                    defaultMessage='Untuk pengaduan & layanan klaim silakan menghubungi Customer Service kami'
                />
            </DetailsSectionText>
            <DetailsSectionBoldText>
                <FormattedMessage 
                    id=' Senin - Jumat (09.00 - 18.00)'
                    defaultMessage=' Senin - Jumat (09.00 - 18.00)'
            />
            </DetailsSectionBoldText>
            <div>
                <DetailsSectionImageCtn>
                    <img src={ImageUrl.SMS} height='24px' width='24px' alt='sms'/>
                    <DetailsSectionText>
                        <AnchorTag href='mailto:cs@tap-insure.com' target="_blank"><FormattedMessage 
                            id='cs@tap-insure.com'
                            defaultMessage='cs@tap-insure.com'
                        /></AnchorTag>
                    </DetailsSectionText>
                </DetailsSectionImageCtn>
                <DetailsSectionImageCtn>
                    <img src={ImageUrl.Whatsapp} height='24px' width='24px' alt='sms'/>
                    <DetailsSectionText underline={true}>
                        <AnchorTag href='https://api.whatsapp.com/send/?phone=62818889019&text&type=phone_number&app_absent=0' target="_blank"><FormattedMessage 
                            id='0818-889-019'
                            defaultMessage='0818-889-019'
                        /></AnchorTag>
                    </DetailsSectionText>
                </DetailsSectionImageCtn>
            </div>
            <DetailsSectionText>
                <FormattedMessage 
                    id='Tim CS kami akan segera menghubungi kamu'
                    defaultMessage='Tim CS kami akan segera menghubungi kamu'
                />
            </DetailsSectionText>
        </DetailsSectionCtn>
    )
}

export default DetailsSection