import styled from 'styled-components'

export const HeaderContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;
    padding: 10px 0;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000;

    .header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
    }

    .logo {
        cursor: pointer;
        width: 170px;
    }

    .header-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .header-item li {
        position: relative;
        text-decoration: none;
        list-style: none;
        cursor: pointer;
        color: #333;
        padding: 16px;
        transition: color 0.3s ease;
        font-size: 14px;
        white-space: normal; 
        word-wrap: break-word; 
        overflow-wrap: break-word; 
        font-weight: 700;
        text-align: left;
    }

    .header-dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        img {
            margin-left: 5px;
            width: 12px;
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 40px;
        left: 0;
        background-color: white;
        list-style: none;
        padding: 10px 0;
        margin: 0;
        z-index: 1000;
        border-radius: 16px;
        cursor: pointer;
    }

    .dropdown-menu li {
        padding: 10px 20px;
        color: #333;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        transition: background-color 0.5s ease;
        font-weight: 700;

        &:hover {
            background-color: #FAF000;
        }
    }

    .header-dropdown:hover .dropdown-menu {
        display: block;
        transition: display 0.5s ease;
    }

    .dropdown-menu {
        display: none;
    }
`;

