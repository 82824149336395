import React from "react";
import {
  FormCtn,
  ImfoCtn,
  InfoText,
  InputContainer,
  InputText,
  InputTextCtn,
  PromptError,
  PromptErrorMsg,
  SearchImageContainer,
  TextLabel,
  TextWrapper,
} from "../InputText/elements";
import { ErrorMessage, Field, useField } from "formik";
import { ImageUrl } from "../../../Images";

const iconGenerator = {
  text: ImageUrl.UpDownArorw,
  date: ImageUrl.Calendar3,
};

export default function TextInputV2({
  type = "text",
  label = "",
  icontype = "text",
  readonly = true,
  showIcon = true,
  infoText = "",
  showLabel=true,
  ...props
}) {
  const [field, meta, helpers] = useField(props.name);

  return (
    <InputTextCtn>
      <TextWrapper disabled={props.disabled} error={meta.touched && !!meta.error}>
        <FormCtn disabled={props.disabled}>
          <Field {...props}>
            {({ field, meta }) => (
              <InputContainer>
                <InputText
                  {...field}
                  {...meta}
                  id={`${props.name}~input`}
                  type={props.type}
                  data-attr-slug={`${props.name}~input`}
                  error={meta.touched && !!meta.error}
                  disabled={props.disabled}
                  showLabel={showLabel}
                  isFilled={field.value}
                  placeholder={label || props.placeholder}
                />
                {field.value ? <TextLabel className={field.value && "filled"}>
                  {field.value
                    ? label
                    : props.placeholder
                    ? props.placeholder
                    : label}
                </TextLabel> : null}
              </InputContainer>
            )}
          </Field>
        </FormCtn>
        {showIcon ? (
          <SearchImageContainer>
            <img
              src={iconGenerator[icontype]}
              width="16px"
              height="16px"
              alt="search-icon"
            />
          </SearchImageContainer>
        ) : null}
      </TextWrapper>
      {infoText && (
        <ImfoCtn>
          <img src={ImageUrl.BlueInfo} alt="info" width="20px" height="20px" />
          <InfoText>{infoText}</InfoText>
        </ImfoCtn>
      )}
      <ErrorMessage
        name={props.name}
        render={(msg) => (
          <PromptError>
            {/* <img src={ImageUrl.Error} width="18px" height="18px" /> */}
            <PromptErrorMsg>
              <div>{msg}</div>
            </PromptErrorMsg>
          </PromptError>
        )}
      />
    </InputTextCtn>
  );
}
