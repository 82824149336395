import styled from 'styled-components'

export const FinanceReportContainer = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
        padding: 0;
        margin: 0;
    }

    .content {
        max-width: 1400px;
        width: 100%;
    }

    h1 {
        font-size: 64px;
        max-width: 448px;
        word-break: break-word;
        margin-left: 78px;
    }

    .content-inner {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        margin-top: 64px;
        margin-bottom: 100px;
    }

    .year-selector {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .year-selector p {
        font-size: 56px;
    }

    .year-selector img {
        width: 14px;
    }

    .finance-report-month-container {
        width: 70%;
    }

    .finance-report-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border-bottom: 2px solid #FAFC00;
    }

    .finance-report-items p {
        font-size: 16px;
    }

    .btn-download {
        border: none;
        background-color: #FAFC00;
        padding: 16px 24px;
        border-radius: 24px;
        cursor: pointer;
        transition: background-color 0.5s ease;

        &:hover {
            background-color: #E5E700;
        }
    }
`