import React from 'react'
import { CorporateGovernanceContainer } from './CorporateGovernance.style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ScrollToTop from '../../components/ScrollToTop'
import { documentLink, imagesLink } from './constant'


const CorporateGovernance = () => {
  return (
    <CorporateGovernanceContainer>
        <Header />
            <div className='hero-section'>
                <h1>Tata Kelola Perusahaan</h1>
                <img src={imagesLink.heroImage}/>
            </div>
            <div className='content'>
                <div className='content-inner'>
                    <p>Sebagai Perusahaan yang bergerak di bidang asuransi umum, PT Asuransi Untuk Semua berkomitmen untuk memberikan layanan dan produk asuransi yang dapat memenuhi kebutuhan dan melindungi semua lapisan masyarakat.</p>
                    <p>Kami percaya bahwa keberhasilan perusahaan tidak hanya ditentukan oleh produk dan layanan yang kami tawarkan, tetapi juga oleh transparansi, akuntabilitas, dan kepatuhan terhadap standar etika yang tinggi. Oleh karena itu, kami memiliki struktur dan proses tata kelola yang kokoh, berlandaskan nilai-nilai inti perusahaan, dan diawasi oleh komite independen yang berdedikasi.</p>
                    <p>Penting bagi kami untuk selalu berkomunikasi dengan jelas dan terbuka kepada semua pihak yang berkepentingan, termasuk Tertanggung, mitra bisnis, karyawan, dan masyarakat umum.</p>
                    <div className='guidelines-container'>
                        <div className='guidelines-items'>
                            <h3>Pedoman Sistem Pelaporan Pelanggan</h3>
                            <a href={documentLink.pedomanPelaporanPelanggar}>Selengkapnya</a>
                        </div>
                        <div className='guidelines-items'>
                            <h3>Pedoman Strategi Anti Fraud</h3>
                            <a href={documentLink.pedomanAntiFraud}>Selengkapnya</a>
                        </div>
                        <div className='guidelines-items'>
                            <h3>Pedoman Tata Kelola Perusahaan</h3>
                            <a href={documentLink.pedomanTataKelola}>Selengkapnya</a>
                        </div>
                        <div className='guidelines-items'>
                            <h3>Pedoman Anti Penyuapan dan Korupsi</h3>
                            <a href={documentLink.pedomanAntiPenyuapan}>Selengkapnya</a>
                        </div>
                    </div>
                </div>
            </div>
        <ScrollToTop />
        <Footer />
    </CorporateGovernanceContainer>
  )
}

export default CorporateGovernance
