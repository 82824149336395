import RoadAssistance from '../../containers/roadAssistance'
import HowToClaim from '../../containers/howToClaim'
import BengkelPage from '../../containers/workshop/workshop'
import PackageDetails from '../../containers/packageDetail'
import TravelForms from '../../containers/tarvelForms'
import ReviewOrder from '../../containers/travelReviewOrder'
import PaymentMethods from '../../containers/paymentMethods'
import PaymentStatus from '../../containers/Payment-Status'
import CustomerSupportForm from '../../containers/CustomerSupportForm'
import WhistleBlowingForm from '../../containers/WhistleBlowing'
const mainPath = 'customer-support'

export const customerSupportroutes = [
  {
    path: 'road-assistance',
    element: <RoadAssistance />
  },
  {

    path: 'how-to-claim',
    element: <HowToClaim />
  },
  {
    path: 'workshop-list',
    element: <BengkelPage/>
  },
  {
    path: 'support-form',
    element: <CustomerSupportForm />
  },
  {
    path: 'whistleblowing',
    element: <WhistleBlowingForm />
  }

].map((route) => ({ ...route, path: `${mainPath}/${route.path}` }))

export const userDataRoutes = [
  {
    path: 'travel/package-details',
    element: <PackageDetails />
  },
  {
    path: 'travel/insured-details',
    element: <TravelForms />
  },
  {
    path: 'travel/review-order',
    element: <ReviewOrder />
  },
  {
    path: 'payment/payment-mode',
    element: <PaymentMethods />
  },
  {
    path: 'payment/payment-status',
    element: <PaymentStatus />
  }
]
