import React, { useEffect, useState } from "react";
import {
  SelectionWrapper,
  SelectInputCtn,
  SelectInput,
  Countries,
  SelectCountryCtn,
  Placeholder,
  SelectCountryName,
  SelectCountry,
  FooterBtnWarpper,
  FooterBtn,
  NoDestinationCtn
} from "./elements";
import { useField } from "formik";
import Checkbox from "../../Checkbox";
import { ImageUrl } from "../../../Images";

const MultiSelectCountry = (props) => {
  const [, , helpers] = useField(props.name);
  const [selectedMap, setSelectedMap] = useState(props.seletcedCountriesMap)
  const [selectedList, setSelectedList] = useState(props.seletcedCountriesList)
  const [countryList, setCountryList] = useState(
    props.countryListOptions || []
  );

  const handleFilter = (val) => {
    if (val === "") {
      setCountryList(props.countryListOptions);
    } else {
      const fileteredList = props.countryListOptions.filter((item) =>
        props.renderer(item).toLowerCase().includes(val.toLowerCase())
      );
      setCountryList(fileteredList);
    }
  };

  const handleChangeOption = (selectedItem) => {
    const itemKey = props.getter(selectedItem)
    if (selectedMap[itemKey]) {
      const newList = [...selectedList]
      const itemIdx = selectedList.findIndex(x => props.getter(x) === itemKey)
      newList.splice(itemIdx, 1)
      setSelectedList([...newList])
      setSelectedMap({ ...selectedMap, [itemKey]: false })
      props.setSelectedCountriesList([...newList])
      props.setSelectedCountriesMap({ ...selectedMap, [itemKey]: true })
    } else if (selectedList.length < 10) {
      setSelectedList([...selectedList, selectedItem])
      setSelectedMap({ ...selectedMap, [itemKey]: true })
      props.setSelectedCountriesList([...selectedList, selectedItem])
      props.setSelectedCountriesMap({ ...selectedMap, [itemKey]: true })
    }
  }

  const handleSubmit = () => {
    if (selectedList.length) {
        helpers.setValue(selectedList.map(o => props.getter(o)).join(','))
        props.onSubmit(selectedList.map(o => props.getter(o)).join(','));
    }
  };

  useEffect(() => {
    const schengenCountries = [];
    for (let i=0; i<props.countryListOptions.length; i++){
      for (let j=0; j<selectedList.length; j++) {
        if(props.countryListOptions[i].schengen && props.countryListOptions[i].country_name === selectedList[j].country_name) {
          schengenCountries.push(props.countryListOptions[i])
        }
      }
    }
    if (schengenCountries.length) {
      props.setShowSchengen(true)
    } else {
      props.setShowSchengen(false)
    }
}, [selectedList, props.options]) 

  return (
    <SelectionWrapper>
      <SelectCountryCtn>
          <SelectCountry>
              <Placeholder>Dari</Placeholder>
              <SelectCountryName>{props.selectCountry}</SelectCountryName>
          </SelectCountry>
          {!props.isInternationalTravel && <img
              src={ImageUrl.Edit}
              width="16px"
              height="16px"
              alt="search-icon"
              onClick={() => props.handleEditFromCountry()}
          />}
      </SelectCountryCtn>
      <SelectInputCtn>
        <SelectInput
          onChange={(e) => handleFilter(e.target.value)}
          placeholder={props.placeholder}
        />
        <img
          src={ImageUrl.Search}
          width="16px"
          height="16px"
          alt="search-icon"
        />
      </SelectInputCtn>
      {countryList.length ? countryList?.map((country) => {
        return (
          <Countries isSelected={selectedMap[country.country_name]}>
            {props.getter(country)}
            <Checkbox name={country.country_name} id={country.country_name} onClick={() => handleChangeOption(country)} checked={selectedMap[props.getter(country)]} type="checkbox"/>
          </Countries>
        );
      }) : <NoDestinationCtn>Tidak ada negara yang ditemukan!!</NoDestinationCtn>}
        <FooterBtnWarpper>
            <FooterBtn disabled={selectedList.length === 0} onClick={() => handleSubmit()}>Submit</FooterBtn>
        </FooterBtnWarpper>
    </SelectionWrapper>
  );
};

export default MultiSelectCountry;
