import ProductAuto from "../../containers/ProductAuto"
import ProductCare from "../../containers/ProductCare"
import ProductDevice from "../../containers/ProductDevice"
import ProductExtendedWarranty from "../../containers/ProductExtendedWarranty"
import ProductTravel from "../../containers/ProductTravel"
import PropertyPage from "../../containers/Property/PropertyPage"
import OtherProductsPage from "../../containers/OtherProducts/OtherProductsPage"

const mainPath = 'product'

const productRoutes = [
  {
    path: 'auto',
    element: <ProductAuto />
  },
  {
    path: 'travel',
    element: <ProductTravel />
  },
  {
    path: 'safe',
    element: <ProductCare />
  },
  {
    path: 'device',
    element: <ProductDevice />
  },
  {
    path: 'extended-warranty',
    element: <ProductExtendedWarranty />
  },
  {
    path: 'property',
    element: <PropertyPage />
  },
  {
    path: 'others',
    element: <OtherProductsPage />
  }
].map((route) => ({ ...route, path: `/${mainPath}/${route.path}` }))

export default productRoutes
