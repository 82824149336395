import styled from 'styled-components';

export const YearDropdownContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100px;

    p {
        font-size: 56px;
    }

    img {
        width: 14px;
    }
`;

export const YearDropdownList = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;

    li {
        padding: 8px 12px;
        cursor: pointer;
    }

    li:hover {
        background-color: #f0f0f0;
    }
`;
