import {useState} from 'react'
import { DatePickerContainer } from '../DateOfBirth/DateOfBirth.style'
import {addDays, format, parse} from 'date-fns'
import Calendar from 'react-calendar'

const TravelPeriod = (props) => {
  const [currentView, setCurrentView] = useState('month');
  const [startDate, setStartDate] = useState(props.startDate || null)
  const [endDate, setEndDate] = useState(null)
  const [showSchedule, setShowSchedule] = useState(props.setSchedule)

  const handleSetFormStart = (val) => {
    const thisYear = new Date(val);
    const oneDay = 24 * 60 * 60 * 1000;
    let nextOneYear = new Date(
      `${thisYear.getFullYear() + 1}-${
        thisYear.getMonth() + 1
      }-${thisYear.getDate()}`
    );
    nextOneYear = new Date(nextOneYear.getTime() - oneDay);
    const formattedDate =  format(val, 'yyyy-MM-dd')
    setStartDate(formattedDate);
    setShowSchedule('endDate');
    props.setShowSchedule('endDate');
    props.setFormValues({
      ...props.formValues,
      trip_start_date: formattedDate
    })
  };

  const handleSetFormEnd = (val) => {
    const thisYear = new Date(val);
    const oneDay = 24 * 60 * 60 * 1000;
    let nextOneYear = new Date(
      `${thisYear.getFullYear() + 1}-${
        thisYear.getMonth() + 1
      }-${thisYear.getDate()}`
    );
    nextOneYear = new Date(nextOneYear.getTime() - oneDay);
    const formattedDate =  format(val, 'yyyy-MM-dd')
    setEndDate(formattedDate);
    props.setFormValues({
      ...props.formValues,
      trip_end_date: formattedDate
    })
    props.setDateModal(false)
  };

  return (
    <DatePickerContainer currentView={currentView}>
        {showSchedule === 'startDate' ? (
            <>
                <Calendar 
                    minDetail="month"
                    defaultView="month"
                    showNeighboringMonth={false}
                    next2Label={null}
                    prev2Label={null}
                    minDate={props.startMinDate || new Date()}
                    maxDate={props.startMaxDate}
                    value={startDate}
                    onChange={handleSetFormStart}
                    tileClassName={({ date, view }) => {
                        if (view === 'month' && date.getDay() === 0) {
                        return 'sunday';
                        }
                    }}
                    formatShortWeekday={(_locale, date) => {
                      return new Intl.DateTimeFormat('id-ID', {
                        weekday: 'long',
                      }).format(date).substring(0,1);
                    }}
                />
            </>
        ) : (
            <>
            <div className='datepicker-subheader2'>
                <div className='startDate'>
                    <div>
                        <h6>Mulai Perjalanan</h6>
                        <h2>{startDate.split('-').reverse().join('/')}</h2>
                    </div>
                    <button onClick={() => {
                        setEndDate(null)
                        setShowSchedule('startDate')
                    }}
                        className='startDate-edit'
                    >
                        <img src='https://storage.googleapis.com/pp_img/tap-travel-website/common/edit-icon.png' width={24}/>
                    </button>
                </div>
            </div>
            <Calendar 
                minDetail="month"
                defaultView="month"
                showNeighboringMonth={false}
                next2Label={null}
                prev2Label={null}
                minDate={props.endMinDate || addDays(parse(startDate, 'yyyy-MM-dd', new Date()), 1)}
                maxDate={props.endMaxDate}
                value={endDate}
                onChange={handleSetFormEnd}
                tileClassName={({ date, view }) => {
                    if (view === 'month' && date.getDay() === 0) {
                    return 'sunday';
                    }
                }}
                formatShortWeekday={(_locale, date) => {
                  return new Intl.DateTimeFormat('id-ID', {
                    weekday: 'long',
                  }).format(date).substring(0,1);
                }}
            />

            </>
        )}
    </DatePickerContainer>
  )
}

export default TravelPeriod
