import React from 'react'
import { AutoContainer } from './ProductAuto.style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { imagesLink } from '../../constants/imageLink'
import { imageLink, riplayDocUrl } from './constant'
import InquiryForm from '../userCriteria/inquiryForm'
import ScrollToTop from '../../components/ScrollToTop'
import { ImageUrl } from '../../Images'

const ProductAuto = () => {
  return (
    <AutoContainer>
        <Header />
        <div className='hero-container'>
            <img src={imagesLink.autoText}/>
            <h1>Nyaman Berkendara, Mobil Terjaga.</h1>
            <h1>Asuransi Auto Siaga</h1>
            <p>Pagi cerah berseri, tiada ragu di setiap langkah,</p>
            <p>Asuransi Auto TAP Insure selalu hadir di sisi, </p>
            <p>kendaraan terlindungi tenang di setiap arah.</p>
            <div className='hero-div'>
                <div className='hero-div-inner'>
                    <div className='hero-div-items'>
                        <p>Perlindungan <strong>Komprehensif</strong></p>
                        <p>Mulai Dari</p>
                        <p className='amount'><span className='super'>Rp</span>2.9jt<span className='normal'>/tahun</span></p>
                    </div>
                    <div className='hero-div-items'>
                        <p>Perlindungan <strong>TLO</strong></p>
                        <p>Mulai dari</p>
                        <p className='amount'><span className='super'>Rp</span>499rb<span className='normal'>/tahun</span></p>
                    </div>
                </div>
            </div>
        </div>
            <div className='content'>
                <div className='content-inner'>
                    <div className='tap-auto-sec2'>
                        <h1 className="section-title">Mengapa Asuransi Auto dari TAP Insure?</h1>
                        <p className="section-subtitle">Kenali apa yang membuat Asuransi Auto Tap Insure berbeda.</p>
                        <img src={imageLink.section2Banner}/>
                    </div>

                    <div className='tap-auto-sec3'>
                        <h1 className='section-title'>Aman dari Risiko Kecil hingga Kerusakan Besar</h1>
                        <p className='section-subtitle'>Membuat setiap perjalanan lebih aman dan menyenangkan. Dengan perlindungan TAP <br />Insure yang meenyeluruh, dari risiko kecil hingga kerusakan besar, kami memberikan <br />keamanan yang Anda butuhkan untuk menjelajahi jalanan dengan penuh percaya diri.</p>
                        <img src={imageLink.section3Banner}/>
                    </div>
                    <div className='tap-auto-sec4'>
                        <h1 className="section-title">Menjamin Kendaraanmu dari Apa Saja?</h1>
                        <p className="section-subtitle">Produk ini melindungi kamu dari kerugian karena:</p>
                        <div className='table-benefit'>
                            <div className='table-header'>
                                <h4>Tipe Kendaraan</h4>
                                <h4>Komprehensif All-Risk</h4>
                                <h4>Kehilangan Total</h4>
                            </div>
                        <div className='table-item-container'>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.mobilPenumpang}/>
                                    Mobil Penumpang
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Ya
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Ya
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.truck}/>
                                    Bus, Truck, Pick-Up
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Ya
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Ya
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className='claim-benefit'>
                            <div className='claim-benefit-items'>
                                <img src={imageLink.carCrash}/>
                            </div>
                            <div className='claim-benefit-items'>
                                <img src={imageLink.carCriminal}/>
                            </div>
                            <div className='claim-benefit-items'>
                                <img src={imageLink.roberry}/>
                            </div>
                            <div className='claim-benefit-items'>
                                <img src={imageLink.lightning}/>
                            </div>
                        </div>
                        <div className='claim-benefit'>
                            <div className='claim-benefit-items'>
                                Tabrakan, benturan, terbalik, tergelincir, terperosok
                            </div>
                            <div className='claim-benefit-items'>
                                Perbuatan Jahat
                            </div>
                            <div className='claim-benefit-items'>
                                Pencurian
                            </div>
                            <div className='claim-benefit-items'>
                                Kebakaran dari sambaran petir
                            </div>
                        </div>
                    </div>
                    <div className='tap-auto-sec5'>
                        <h1 className="section-title">Jika Dibutuhkan...</h1>
                        <p>Manfaat lainnya dari Asuransi Auto Tap Insure:</p>
                        <h2>Perlindungan saat terjadi kecelakaan</h2>
                        <div className='tap-auto-sec5-inner'>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.manHitbyCar}/>
                                <h3>Tanggung Jawab Pihak Ketiga</h3>
                                <p>Lindungi dirimu dari tuntutan pihak ketiga dengan perlindungan hingga Rp50.000.000 untuk kerusakan atau cedera akibat kendaraanmu.</p>
                            </div>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.hammerLaw}/>
                                <h3>Tanggung Jawab Hukum Penumpang</h3>
                                <p>Perlindungan hukum bagi penumpangmu, untuk perjalanan aman tanpa khawatir hingga 
                                Rp50.000.000.</p>
                            </div>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.manWithBandage}/>
                                <h3>Pertanggungan Kecelakaan Pengemudi</h3>
                                <p>Jaminan perlindungan bagi pengemudi, karena keselamatanmu adalah prioritas kami hingga 
                                Rp10.000.000.</p>
                            </div>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.manWithBandage}/>
                                <h3>Pertanggungan Kecelakaan Penumpang</h3>
                                <p>Perlindungan ekstra bagi setiap penumpang, untuk perjalanan yang tenang dan aman hingga Rp40,000,000</p>
                            </div>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.ambulance}/>
                                <h3>Pertanggungan Biaya Ambulans 24/7</h3>
                                <p>Evakuasi medis tanpa khawatir. TAP Insure tanggung biaya ambulans Anda dalam situasi darurat hingga Rp 500,000
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='tap-auto-sec5'>
                        <h2>Tambahan Layanan</h2>
                        <div className='tap-auto-sec5-inner'>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.toolkit}/>
                                <h3>Servis Bengkel Resmi*</h3>
                                <p>Perawatan kendaraan di bengkel resmi dengan jaminan kualitas terbaik dan suku cadang asli.</p>
                            </div>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.towTruck}/>
                                <h3>Biaya Derek Jabodetabek</h3>
                                <p>Layanan derek gratis hingga Rp2.500.000 di Jabodetabek. Kendaraanmu aman bersama TAP Insure.</p>
                            </div>
                            <div className='tap-auto-sec5-items'>
                                <img src={imageLink.waterHammer}/>
                                <h3>Water Hammer*</h3>
                                <p>Jaminan perlindungan bagi pengemudi hingga Rp10.000.000.</p>
                            </div>
                        </div>
                        <p className="optional">*optional dengan tambahan premi</p>
                    </div>
                    <div className="tap-auto-sec6">
                        <h2>Pertanggungan Keadaan Ekstrim</h2>
                        <div className='tap-auto-sec6-inner'>
                            <div className="tap-auto-sec6-items">
                                <img src={imageLink.flood} />
                                <h3>Banjir & Angin Topan</h3>
                                <p>Kendaraan kamu aman dari banjir dan angin topan. Kami siap melindungi di saat cuaca ekstrem melanda.</p>
                            </div>
                            <div className="tap-auto-sec6-items">
                                <img src={imageLink.tsunami} />
                                <h3>Gempa Bumi & Tsunami</h3>
                                <p>Perlindungan kendaraan dari gempa bumi dan tsunami, untuk keamanan di saat bencana alam melanda.</p>
                            </div>
                            <div className="tap-auto-sec6-items">
                                <img src={imageLink.demonstration} />
                                <h3>Huru Hara & Kerusuhan</h3>
                                <p>Lindungi kendaraan kamu dari kerusakan akibat huru hara dan kerusuhan. Keamanan kendaraan kamu adalah prioritas kami.</p>
                            </div>
                            <div className="tap-auto-sec6-items">
                                <img src={imageLink.terrorism} />
                                <h3>Aksi Teroris & Sabotase</h3>
                                <p>Kendaraan kamu terlindungi dari ancaman terorisme dan sabotase. TAP Insure hadir untuk melindungi dari segala risiko ekstrem.</p>
                            </div>
                        </div>
                    </div>
                    <div className="tap-auto-sec7">
                        <h1 className="section-title">Yuk Jelajahi Informasi Lebih Lanjut!</h1>
                        <p className="section-subtitle">Lindungi kendaraan kamu hanya dengan beberapa ketukan.</p>
                        <div className='flex-ctn'>
                            <a href={riplayDocUrl} target='_blank'>
                            <button className="btn-auto">
                                Unduh Informasi Produk
                            </button>
                            <button className="btn-auto-circle"><img src={ImageUrl.DownloadWhite} width='17px' height='17px' alt='download'/></button>
                            </a>
                        </div>
                        <img src={imageLink.section7Banner}/>
                    </div>
                    <div className='tap-auto-sec8'>
                        <h1 className='section-title'>Dapatkan Perlindunganmu disini!</h1>
                        <p className='section-subtitle'>Lindungi dirimu hanya dengan beberapa ketukan.</p>
                        <div className='sales-form'>
                            <InquiryForm product={"Asuransi Auto"}/>
                        </div>
                    </div> 
                    <div className='tap-auto-sec9'>
                        <h1 className='section-title'>Saat Hidup Membawa Kejutan,<br />TAP Insure Siap Memberikan Perlindungan.</h1>
                        <p className='section-subtitle'>Dengan Asuransi Auto, kamu bisa fokus berkendara tanpa rasa khawatir. Dari kerusakan kecil <br />hingga kecelakaan besar, Tap Insure selalu siap memberikan perlindungan bagi kamu dan <br />kendaraanmu. Karena keselamatanmu adalah prioritas utama kami di setiap perjalanan.</p>
                        <img src={imageLink.section9Banner}/>
                    </div>
                </div>
            </div>
        <ScrollToTop />
        <Footer />
    </AutoContainer>
  )
}

export default ProductAuto
