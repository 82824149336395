// src/components/Styled.js
import styled from 'styled-components';
import { imagesLink } from './constants';

export const Container = styled.div`
  text-align: center;
  @media screen and (max-width: 720px) {
    max-width: 100vw;
  }
`;

export const MainHeading = styled.h1`
  font-size: 2.5em;
  margin-top: 20px;
  padding: 0px 400px;
  @media screen and (max-width: 720px) {
    padding: 0px 20px;
  }
`;

export const ClaimButton = styled.button`
  background-color: #FFF500;
  color: #000;
  font-size: 1.2em;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  color: #000;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 288.002px;
  height: 48px;
  flex-shrink: 0;
`;

export const StepsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 40px auto;
  max-width: 800px;
  column-gap: 80px;
  row-gap: 64px;
  flex-wrap: wrap;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
  font-weight: 400;

  b{
    font-weight: 800;
  }
`;

export const Step = styled.div`
  text-align: center;
  max-width: 180px;
`;

export const StepIcon = styled.img`
  width: 212px;
  height: 186px;
`;

export const Footer = styled.footer`
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
`;

export const DownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

export const DownalodIcon = styled.img`
  width: 24.199px;
  height: 29.748px;
  `

export const DownloadButton = styled.a`
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 20px;
  text-decoration: none;
  color: #000;
  background-color: #FAF000;
  align-items: center;
  display: flex;
  font-weight: 800;
  gap: 14px;
  transition: background-color 0.5s ease;

  .download-text {
    font-size: 12px;
    text-align: left;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
  }

  &:hover {
    background-color: #d9d004;
  }
`;

export const Body1Container = styled.div`
  background: linear-gradient(180deg, #D9D9D9 0%, #FFF 100%);
  padding: 80px;
  padding-bottom: 20px;
`

export const ClaimStepsHeadingContainer = styled.h2`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 140% */
`

export const ClaimStepsSubHeading = styled.p` 
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  b{
    color: #000;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
  }
`
export const DownloadCTAHeading = styled.h2`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px; /* 140% */
  padding: 0px 350px;
  @media screen and (max-width: 720px) {
    padding: 0px 28px;
  }
`

export const SecurityMessage = styled.p`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  @media screen and (max-width: 720px) {
    padding: 0px 28px;
  }
  `

export const BannerContainer = styled.div`
  background-image: url(${imagesLink.howToClaimBackdropBanner});
  margin-top: -20px;
  z-index: -1;
  position: relative;
  background-size: cover;
  @media screen and (max-width: 720px) {
    max-width: 100vw;
  }
`

export const BannerImage = styled.img`
  @media screen and (max-width: 720px) {
    max-width: 100vw;
  }
`

export const FamilyBannerImage = styled.img`
  @media screen and (max-width: 720px) {
    max-width: 100vw;
    object-fit: cover;
  }
`

export const GoDownArrowContainer = styled.button`
    background: #FAF000;
    border: none;
    padding: 12px 16px;
    border-radius: 50%;
    height: 48px;
`
export const BannerButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
