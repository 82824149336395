export const imagesLink = {
    tapInsureAdvantage : 'https://storage.googleapis.com/pp_img/tap-travel-website/Homepage/Tap%20Insure%20Advantage.png',
    travelLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/travel-logo-horizontal.svg',
    autoLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/auto-logo-horizontal.svg',
    deviceLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/device-logo-horizontal.svg',
    careLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/care-logo-horizontal.svg',
    propertyLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/property-logo-horizontal.svg',
    warrantyLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/warranty-logo-horizontal.svg',
    shippingLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/shipping-logo-horizontal.svg',
    healthLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/health-logo-horizontal.svg',
    bannerBottom: 'https://storage.googleapis.com/pp_img/tap-travel-website/Homepage/Banner%20Bottom.png'
}