import styled from 'styled-components'

export const PropertyContainer = styled.div`
  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
  }

  .m-50 {
    margin-bottom: 50px;
  }
  
  .mid-image{
    width: 50%;
    height: 500px;
    margin: auto;
  }

`

export const ProductListWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  margin-bottom: 50px;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(to bottom, #D9D9D9, #FFFFFF);
  gap: 100px;
`

export const ProductListIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  align-items: center;
`

export const ProductListIconContainer = styled.div`
  height: 100px;
  width: 100px;
  border: ${props => props.active ? '5px solid #EB85B5' : '5px solid #FFFFFF'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
`

export const ProductListIcon = styled.img`
  height: 68px;
  width: 68px;
`

export const ProductListTitle = styled.div`
  font-family: Mulish;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 17px;
  text-align: center;
  width: 70px;
  color: ${(props) => (props.isActive ? "#333333" : "#999999")};
`
export const ProductButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`
export const ProductButton = styled.button`
  color: #FFFFFF;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  border-radius: 100px;
  background: #EB85B5;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  cursor: pointer;
  ont-weight: 600;
  transition: background-color 0.5s ease;

  &:hover {
      background-color: #d86ea0;
  }
`

export const ProductBtnArrow = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: 50%;
  background: #EB85B5;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
      background-color: #d86ea0;
  }
`
export const ProductDescription = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
`
export const ProductHeaderText = styled.div`
  font-family: Mulish;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  color: ${props => props.textColor ? props.textColor : '#000000'}
`
export const AnchorTag = styled.a`
  text-decoration: none;
  display: block;
  width: fit-content;
  margin: auto;
`