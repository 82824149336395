import React, { useState } from 'react';
import { SliderContainer, SliderWrapper, Card, ArrowButton, ArrowIcon, Goto } from './CardSlider.style'; 
import { imagesLink as HomepageImage } from '../../containers/Homepage/constants';
import {imagesLink} from '../../constants/imageLink'

const cardData = [
  { id: 1, icon: HomepageImage.travelLogoHorizontal, description: "Teman setia untuk petualangan yang aman dan mengesankan.", source: ''},
  { id: 2, icon: HomepageImage.autoLogoHorizontal, description: "Bepergian nyaman dengan asuransi kendaraan bermotor.", source: '' },
  { id: 3, icon: HomepageImage.deviceLogoHorizontal, description: "Perlindungan terbaik untuk semua jenis gadget kesayangan Anda.", source: '' },
  { id: 4, icon: HomepageImage.careLogoHorizontal, description: "Hidup tanpa rasa khawatir karena semua sudah terlindungi.", source: '' },
  { id: 5, icon: HomepageImage.propertyLogoHorizontal, description: "Rumah aman dan nyaman, bebas rasa khawatir kapanpun.", source: '' },
  { id: 6, icon: HomepageImage.warrantyLogoHorizontal, description: "Garansi panjang, kendaraan tetap tenang, selalu terjaga.", source: '' },
  { id: 7, icon: HomepageImage.shippingLogoHorizontal, description: "Kiriman apapun dengan aman, sampai tujuan tanpa hambatan.", source: '' },
  { id: 8, icon: HomepageImage.healthLogoHorizontal, description: "Sehat setiap hari terjaga dan beraktifitas tanpa rasa khawatir.", source: '' },
];

const CardSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 4; // Number of cards to show at a time

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - itemsToShow);
    }
  };

  const handleNext = () => {
    if (currentIndex + itemsToShow < cardData.length) {
      setCurrentIndex(currentIndex + itemsToShow);
    }
  };

  // Slice the array to show only the current visible cards
  const visibleCards = cardData.slice(currentIndex, currentIndex + itemsToShow);

  return (
    <SliderContainer>
      <ArrowButton direction="left" onClick={handlePrev} disabled={currentIndex === 0}>
        <ArrowIcon src={imagesLink.arrowLeft} alt="Left Arrow" />
      </ArrowButton>

      <SliderWrapper>
        {visibleCards.map((card) => (
          <Card key={card.id}>
            <img src={card.icon} className='card-icon'/>
            <p>{card.description}</p>
            
            <Goto href={card.source}>
                <img src={imagesLink.arrowRight2}/>
            </Goto>
          </Card>
        ))}
      </SliderWrapper>

      <ArrowButton direction="right" onClick={handleNext} disabled={currentIndex + itemsToShow >= cardData.length}>
        <ArrowIcon src={imagesLink.arrowRight} alt="Right Arrow" />
      </ArrowButton>
    </SliderContainer>
  );
};

export default CardSlider;
