import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { FooterCtn, FormContainer } from "./elements";
import SupportForm from "./supportForm";
import DetailsSection from "./detailsSection";
import ScrollToTop from "../../components/ScrollToTop";

const CustomerSupportForm = () => {
  return (
    <div>
      <Header />
      <FormContainer>
        <DetailsSection />
        <SupportForm />
      </FormContainer>
      <FooterCtn />
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default CustomerSupportForm;
