import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const MainHeading = styled.h1`
  font-size: 2.5em;
  margin-top: 20px;
  font-family: Mulish;
`;

export const SearchSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-around;
  background-color: #FFF;
  padding: 24px;
  width: 100%;
  border-radius: 16px;
  align-items: center;
`;

export const Input = styled.input`
  border: 0px solid #ccc;
  border-radius: 5px;
  width: 200px;
  outline: none;
  ::placeholder{
    color: #666;
    font-family: Mullish;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const SearchIcon = styled.img`
  width: 30px;
  height: 30px;
`

export const SearchInputSection = styled.div`
  display: flex;
  align-items: center;
`

export const SearchButtonSection = styled.div``

export const Button = styled.button`
  background-color: ${props => props.background || '#FAF000'};
  color: ${props => props.color || '#000'};
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 48px;
`;

export const Table = styled.table`
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  margin: 0 auto;
`;

export const TableHeader = styled.th`
  border-bottom: 2px solid #ffd700;
  padding: 16px 0px;
  text-align: justify;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
`;

export const Footer = styled.footer`
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  margin-top: 40px;
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`
export const BannerArrowDownIconContainer = styled.div`
  width: 30px;
  height: 120px;
  flex-shrink: 0;
  background-color: #000;
  border-radius: 20px;
  @media screen and (max-width: 720px) {
    display : none;
  }
`
export const BannerArrowDownIcon = styled.img``
export const BannerImageContainer = styled.div`
  @media screen and (max-width: 720px) {
    max-width: 100%;
  }
`
export const BannerImage = styled.img`
  width: 100vw;
  @media screen and (max-width: 720px) {
    max-width: 100%;
  }
`

export const TableContainer = styled.div`
    padding: 0px 96px;
`

export const ContentInner = styled.div `
  width: 100vw;
  background: rgb(217,217,217);
  background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
  background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
  background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);
  padding-top: 48px;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
`

export const PaginationButtonLeft = styled.button`
  width: 40px;
  height: 40px;
  background-color: #D9D9D9;
  transition: background-color 0.5s ease;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: #adadad;
  }

  &:disabled {
    background-color: #f0f0f0; 
    cursor: not-allowed; 
    opacity: 0.6; 
  }
`

export const PaginationButtonRight = styled.button`
  width: 40px;
  height: 40px;
  background-color: #D9D9D9;
  transition: background-color 0.5s ease;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #adadad;
  }

  &:disabled {
    background-color: #f0f0f0; 
    cursor: not-allowed; 
    opacity: 0.6; 
  }
`