import React from "react";
import { InquirySendOk, InquirySendText, InquirySendWrapper, SendTitle } from "./elements";
import { ImageUrl } from "../../Images";
import { FormattedMessage } from "react-intl";

const SupportFormSend = (props) => {
    return (
        <InquirySendWrapper>
            <img width='64px' height='64px' src={ImageUrl.SentBlack} alt='send'/>
            <SendTitle><FormattedMessage id='Terima Kasih Telah Menghubungi TAP Insure!' defaultMessage='Terima Kasih Telah Menghubungi TAP Insure!'/></SendTitle>
            <InquirySendText>
                <FormattedMessage 
                    id='Pesan Anda sudah terkirim ke sistem kami. Tim Marketing TAP Insure akan segera menghubungi Anda dalam waktu maksimal 1x24 jam. Terima kasih.'
                    defaultMessage='Pesan Anda sudah terkirim ke sistem kami. Tim Marketing TAP Insure akan segera menghubungi Anda dalam waktu maksimal 1x24 jam. Terima kasih.'
                />
            </InquirySendText>
            <InquirySendOk
                id="send-btn"
                loading={false}
                onClick={() => props.setShowModal(false)}
                type="yellow"
            >
                Tutup
            </InquirySendOk>
        </InquirySendWrapper>
    )
}

export default SupportFormSend