import styled from "styled-components";

export const OptionsWrappper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${(props) => props.length}, 1fr);
`;
export const OptionBtn = styled.div`
  padding: 16px 16px 16px 16px;
  gap: 8px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background: ${props => props.isDisabled ? '#EEEEEE' : '#FFFFFF'};
  color: ${(props) => ((props.isSelected && !props.isDisabled) ? "#333333" : "#999999")};
  border: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
  border-right: none;
`;
export const OptionBtn2 = styled.div`
  padding: 16px 16px 16px 16px;
  gap: 8px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: #ffffff;
  background: ${props => props.isDisabled ? '#EEEEEE' : '#FFFFFF'};
  color: ${(props) => ((props.isSelected && !props.isDisabled) ? "#333333" : "#999999")};
  border: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
`;

export const PromptError = styled.div`
  width: 100%;
  margin: 8px 0px;
  display: flex;
  gap: 1%;
`;
export const PromptErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #DC0000;
`;
export const OptionstCtn = styled.div`
  width: 100%;
`;
