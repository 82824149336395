import { Routes, Route } from 'react-router-dom';
import aboutUsRoutes from './aboutUs';
import productRoutes from './product';
import publicationRoutes from './publication';
import NotFoundPage from '../components/404NotFound';
import {customerSupportroutes, userDataRoutes} from './customerSupport'
import Homepage from '../containers/Homepage';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import TermsCondition from '../containers/TermsCondition/TermsCondition';
import { IntlProvider } from 'react-intl'

const routes = [
  ...customerSupportroutes,
  ...aboutUsRoutes,
  ...userDataRoutes,
  ...productRoutes,
  ...publicationRoutes,
  ...userDataRoutes,
  ...productRoutes
];

function AppRouters() {
  return (
    <IntlProvider onError={() => null}>
      <Routes>
        <Route path="/" element={<Homepage />} /> 
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path="/terms-condition" element={<TermsCondition />}/>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact
                key={singleRoute.path}
                path={path}
                {...otherProps}
              />
            );
          })}

          <Route path="*" element={<NotFoundPage />} />
      </Routes>

    </IntlProvider>
  );
}

export default AppRouters;
