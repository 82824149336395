export const imagesLink = {
  PropertyHeading: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/propertyheading.png',
  PropertyFireWhy: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/why.png',
  PropertyFireBenefit: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/protection_property%20(1).png',
  PropertyFireInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/propery_fire_info.png',
  PropertyFireOthers: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/benefits.png',
  PropertyRiskWhy: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_risk_why.png',
  PropertyRiskBenefit: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_risk_benefit.png',
  PropertyMoreInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/more_info.png',
  PropertyAllBenefit: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/all%20benefits.png',
    PropertyFooterImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property-banner.png'
}

export const PROPERTY_TYPE = [
  {
    key: 'fire',
    activeIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_fire.png',
    label: 'Property Fire',
    productName:'fire',
  },
  {
    key: 'risk',
    activeIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_all_risk.png',
    label: 'Property All Risk',
    productName:'risk',
  }
]

export const riplayDocUrl = {
  risk: 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Property%20-%20All%20Risk.pdf',
  fire: 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Property%20-%20Fire.pdf'
}