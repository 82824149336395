import React from 'react';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
  Container,
  MainHeading,
  StepsContainer,
  Step,
  StepIcon,
  DownloadButtons,
  DownloadButton,
  Body1Container,
  ClaimStepsHeadingContainer,
  ClaimStepsSubHeading,
  DownloadCTAHeading,
  SecurityMessage,
  DownalodIcon,
  BannerContainer,
  BannerImage,
  FamilyBannerImage,
} from './howToClaim.style';
import { strings, steps, imagesLink } from './constants';
import ScrollToTop from '../../components/ScrollToTop';

const ClaimPage = () => {
  return (
    <Container>
      <Header />    
      <MainHeading>{strings.mainHeading}</MainHeading>
      <BannerContainer>
        <BannerImage src={imagesLink.bannerForeGround} />
      </BannerContainer>

      <Body1Container>
        <ClaimStepsHeadingContainer>{strings.claimStepsHeading}</ClaimStepsHeadingContainer>
        <ClaimStepsSubHeading>{strings.claimProcessDescription} <b>{strings.claimHashTag}</b></ClaimStepsSubHeading>

        <StepsContainer>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIcon src={step.icon} alt={`Step ${index + 1}`} />
              <p dangerouslySetInnerHTML={{ __html: step.description }} />
            </Step>
          ))}
        </StepsContainer>
      </Body1Container>


      <DownloadCTAHeading>{strings.downloadCTA}</DownloadCTAHeading>
      <SecurityMessage>{strings.securityMessage}</SecurityMessage>

      <DownloadButtons>
        <DownloadButton href="#">
          <DownalodIcon src={imagesLink.appleIcon} />
          <div className='text-container'>
            <p className='download-text'>{strings.downloadText}</p>
            <p>{strings.appStore}</p>
          </div>
        </DownloadButton>
        <DownloadButton href="#">
          <DownalodIcon src={imagesLink.playStoreIcon} />
          <div className='text-container
        '>
            <p className='download-text'>{strings.foundOn}</p>
            <p>
              {strings.playStore}
            </p>
          </div>
        </DownloadButton>
      </DownloadButtons>
      <FamilyBannerImage
        src={imagesLink.familyClaimApply}
      />
      <ScrollToTop />
      <Footer />
    </Container>
  );
};

export default ClaimPage;
