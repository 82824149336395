import React from 'react'
import { HomePageContainer } from './HomePage.style'
import { imagesLink } from './constants'
import Header from '../../components/Header'
import CardSlider from '../../components/CardSlider'
import Footer from '../../components/Footer'
import ScrollToTop from '../../components/ScrollToTop'
import UserCriteria from '../../containers/userCriteria'

const HomePage = () => {
  return (
    <HomePageContainer>
        <Header />
        <UserCriteria />
        <div className='hero-section'>
          <img src={imagesLink.tapInsureAdvantage}/>
        </div>
        <div className='product'>
          <h1>Temukan yang Sesuai dengan Kebutuhan Kamu</h1>
          <CardSlider />
        </div>
        <div className='hero-section'>
          <img src={imagesLink.bannerBottom}/>
        </div>
        <ScrollToTop />
        <Footer />
    </HomePageContainer>
  )
}

export default HomePage
