import styled from "styled-components";
import LargeButton from "../../components/LargeButton";

export const FormContainer = styled.div`
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 40% 57%;
    gap: 3%;
    margin-top: 100px;
`
export const SupportFormWarpper = styled.div`
`
export const SupportFormCtn = styled.div`
    background: #FFFFFF;
    padding: 32px;
    border-radius: 32px;
    box-shadow: 0px 5px 16px 0px #080F340F;
    border: 1px solid #EFF0F6;
    margin-top: 60px;
    width: 100%;
`
export const DetailsSectionCtn = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
`
export const FlexCtn = styled.div`
    display: flex;
    flex-direction: column;
`
export const Title = styled.div`
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    height: 34px;
    display: flex;
    align-items: center;
`
export const GridItem = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3%;
`
export const FormItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
`

export const DropDownList = styled.ul`
    position: absolute;
    top: 80px;
    left: 0;
    background-color: white;
    list-style: none;
    padding: 10px 0;
    margin: 0;
    z-index: 1000;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    cursor: pointer;
    width: calc(100% - 4px);
    border-bottom: 2px solid #eeeeee;
    border-right: 2px solid #eeeeee;
    border-left: 2px solid #eeeeee;
`
export const DropDownListElement = styled.li`
    padding: 10px 20px;
    color: #333;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    transition: background-color 0.5s ease;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: left;

    &:hover {
        background-color: #FAF000;
    }
`
export const PorductItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`
export const FormSubmitBtnCtn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 28px;
    width: 100%;
    padding: 0px 0px 0px 64px;
`
export const FormSubmitBtn = styled(LargeButton)`
    width: 200px;
    margin: inherit;
`
export const TncText = styled.div`
    font-family: Mulish;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin-top: 20px;
`
export const TncSpan = styled.span`
    font-weight: 800;
`
export const TncUnderLine = styled.span`
    font-weight: 800;
    text-decoration: underline;
    text-decoration-color: #FAF000;
    text-decoration-thickness: 3px;
    cursor: pointer;
`
export const DetailsSectionHeader = styled.div`
    font-family: Mulish;
    font-size: 40px;
    font-weight: 800;
    line-height: 56px;
    text-align: left;
`
export const DetailsSectionText = styled.div`
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    text-decoration: ${props => props.underline ? 'underline' : 'none'};
`
export const DetailsSectionBoldText = styled.div`
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
`
export const DetailsSectionImageCtn = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`
export const FooterCtn = styled.div`
    margin-top: -455px;
    height: 450px;
    padding-left: 10%;
    padding-top: 30px;
    background-image: linear-gradient(to bottom, #dbdbdb, #ffffff);
`
export const AnchorTag = styled.a`
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-decoration: none;
    color: #333333;
`
export const AnchorTag2 = styled.a`
    text-decoration: none;
    color: #333333;
    font-weight: 700;
    text-decoration: underline;
    font-family: Mulish;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
`
export const ImageCtn = styled.div`
    position: absolute;
    bottom: 450px;
`
export const TermsB = styled.b`
  font-family: 'Mulish';
  font-weight: 700;
  text-decoration: underline;
  font-family: Mulish;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
`
export const InquirySendWrapper = styled.div`
  padding: 48px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
export const InquirySendText = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
`;
export const InquirySendOk = styled(LargeButton)`
  width: 200px;
`;
export const CaptchaErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: Mulish;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #dc0000;
`;
export const SendTitle = styled.div`
    font-family: Mulish;
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`
export const CaptchaWrapper = styled.div`
    margin-left: -50px;
`