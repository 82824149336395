import styled from "styled-components";

export const ProductCtn = styled.div`
  width: 100%;
`;

export const ProductListWrapper = styled.div`
  width: 55%;
  margin: 0px auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-evenly;
`;
export const ProductListIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
`;
export const ProductListIcon = styled.img`
  height: 68px;
  width: 68px;
  margin-top: 30px;

  &:hover {
    opacity: 0.8;
  }
`;
export const ProductListTitle = styled.div`
  font-family: Mulish;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 17px;
  text-align: center;
  color: ${(props) => (props.isActive ? "#333333" : "#999999")};
`;
export const ProductTopCtn = styled.div`
  position: relative;
  background: url(${(props) => (props.bgImg ? props.bgImg : "")});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #ffffff;
  height: 420px;
  min-height: 420px;
  max-height: 420px;
  transition: background 3s;
`;
export const ProductDescriptionCtn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const ProductDescriptionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 10%;
  width: 44%;
  align-items: baseline;
`;
export const ProductDescriptionHeader = styled.div`
  font-family: Mulish;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: left;
  color: ${props => props.textColor ? props.textColor : '#000000'};
`;
export const ProductDescriptionContent = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${props => props.textColor ? props.textColor : '#000000'}
`;
export const ProductDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const ProductDescriptionButton = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  border-radius: 100px;
  background: #FAF000;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  cursor: pointer;

  &:hover {
      background-color: #ece30c;
  }
`
export const ProductDescriptionBtn = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: 50%;
  background: #FAF000;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
      background-color: #ece30c;
  }
`
