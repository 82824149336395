import { useState } from "react";
import {
  PropertyContainer,
  ProductListIcon,
  ProductListIconWrapper,
  ProductListWrapper,
  ProductListTitle,
  ProductListIconContainer,
  ProductButtonContainer,
  ProductButton,
  ProductBtnArrow,
  ProductDescription,
  ProductHeaderText,
  AnchorTag,
} from "./PropertyPage.style";
import Header from "../../components/Header";
import { imagesLink, PROPERTY_TYPE, riplayDocUrl } from "./constants";
import Footer from "../../components/Footer";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";
import InquiryForm from "../userCriteria/inquiryForm";
import ScrollToTop from "../../components/ScrollToTop";

const PropertyPage = () => {
  const [activeProduct, setActiveProduct] = useState("fire");

  return (
    <PropertyContainer>
      <Header />
      <div className="section">
        <img src={imagesLink.PropertyHeading} />
      </div>
      <ProductListWrapper>
        {PROPERTY_TYPE.map((product) => {
          return (
            <ProductListIconWrapper
              onClick={() => {
                setActiveProduct(product.productName);
              }}
            >
              <ProductListIconContainer
                active={activeProduct === product.productName}
              >
                <ProductListIcon src={product.activeIcon} alt={product.key} />
              </ProductListIconContainer>
              <ProductListTitle
                isActive={activeProduct === product.productName}
              >
                {product.label}
              </ProductListTitle>
            </ProductListIconWrapper>
          );
        })}
      </ProductListWrapper>
      {activeProduct === "fire" && (
        <>
          <div className="section m-50">
            <img src={imagesLink.PropertyFireWhy} />
          </div>
          <div className="section m-50">
            <img src={imagesLink.PropertyFireInfo} />
          </div>
          <div className="section m-50">
            <img src={imagesLink.PropertyFireBenefit} />
          </div>
          <div className="section">
            <img src={imagesLink.PropertyFireOthers} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mau Tau Lebih Lanjut?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="Cek info lengkap Asuransi Property Fire Tap Insure sekarang!"
              defaultMessage="Cek info lengkap Asuransi Property Fire Tap Insure sekarang!"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <AnchorTag href={riplayDocUrl.fire} target="_blank">
            <ProductButtonContainer>
              <ProductButton>
                <FormattedMessage
                  id="Unduh"
                  defaultMessage="Unduh Informasi Produk"
                />
              </ProductButton>
              <ProductBtnArrow padding="0px">
                <img src={ImageUrl.DownloadWhite} width="17px" height="17px" />
              </ProductBtnArrow>
            </ProductButtonContainer>
          </AnchorTag>
        </>
      )}
      {activeProduct === "risk" && (
        <>
          <div className="section m-50">
            <img src={imagesLink.PropertyRiskWhy} />
          </div>
          <div className="section">
            <img src={imagesLink.PropertyRiskBenefit} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mau Tau Lebih Lanjut?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="Cek info lengkap Asuransi Property All Risk Tap Insure sekarang!"
              defaultMessage="Cek info lengkap Asuransi Property All Risk Tap Insure sekarang!"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <AnchorTag href={riplayDocUrl.risk} target="_blank">
            <ProductButtonContainer>
              <ProductButton>
                <FormattedMessage
                  id="Unduh"
                  defaultMessage="Unduh Informasi Produk"
                />
              </ProductButton>
              <ProductBtnArrow padding="0px">
                <img src={ImageUrl.DownloadWhite} width="17px" height="17px" />
              </ProductBtnArrow>
            </ProductButtonContainer>
          </AnchorTag>
        </>
      )}
      <div className="section">
        <img src={imagesLink.PropertyFooterImage} width="50%" height="600px" />
      </div>
      <InquiryForm product="Asuransi Property" />
      <div className="section">
        <img src={imagesLink.PropertyAllBenefit} />
      </div>
      <ScrollToTop />
      <Footer />
    </PropertyContainer>
  );
};

export default PropertyPage;
