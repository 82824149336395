import styled from 'styled-components'

export const LargeButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0px 16px;
  height: 48px;
  width: fit-content;
  margin: auto;
  transition: all 0.2s linear;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  
  background-color: ${(props) => {
    if (props.disabled) {
      return '#EEEEEE'
    } else if (props.type === 'secondary') {
      return '#B43CFA'
    } else if (props.type === 'mono') {
      return '#FFFFFF'
    } else if (props.type === 'blue') {
      return '#FFFFFF'
    } else if (props.type === 'yellow') {
      return '#FAF000'
    } else {
      return '#B43CFA'
    }
  }};

  ${props => props.type === 'blue' && !props.disabled && (
    `
      border: 2px solid #B43CFA;
    `
  )}
`

export const LargeButtonContent = styled.div`
  font-family: Mulish;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.6rem;
  transition: all 0.2s linear;
  color: ${(props) => {
    if (props.disabled) {
      return '#CCCCCC'
    } else if (props.type === 'secondary') {
      return '#FFFFFF'
    } else if (props.type === 'blue') {
      return '#B43CFA'
    } else if (props.type === 'yellow') {
      return '#333333'
    } else {
      return '#B43CFA'
    }
  }};
  text-align: center;
  letter-spacing: 0.02em;
`

export const LargeButtonSpinner = styled.div`
  border: 4px solid #f3f3f3; 
  border-top: 4px solid '#666666';
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
  margin-left: 8px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
