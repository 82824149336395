
import { BrowserRouter } from 'react-router-dom';
import AppRouters from './routes';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
      <AppRouters/>
       </BrowserRouter>
    </div>
  );
}

export default App;
