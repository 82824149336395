import styled from 'styled-components';

export const HomePageContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;

    // * {
    //     margin: 0;
    //     padding: 0;
    // }

    background-image: linear-gradient(to bottom, #dbdbdb, #ffffff);
    
    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 32px;

        img {
            max-width: 1200px;
            width: 100%;
        }
    }

    .product {
        h1 {
            text-align: center;
            margin-bottom: 24px;
        }
    }

    /* Responsive Styling */
    @media (max-width: 1200px) {
        .hero-section img {
            max-width: 1000px;
            width: 100%;
        }
    }

    @media (max-width: 992px) {
        .hero-section img {
            max-width: 800px;
        }

        .product h1 {
            font-size: 24px;
        }
    }

    @media (max-width: 768px) {
        .hero-section img {
            max-width: 600px;
        }

        .product h1 {
            font-size: 22px;
            margin-bottom: 20px;
        }

        .hero-section {
            padding: 24px;
        }
    }

    @media (max-width: 576px) {
        .hero-section img {
            max-width: 400px;
        }

        .product h1 {
            font-size: 20px;
            margin-bottom: 16px;
        }

        .hero-section {
            padding: 16px;
        }
    }

    @media (min-width: 1600px) {
        .hero-section img {
            max-width: 1400px;
        }
    }

    @media (min-width: 1800px) {
        .hero-section img {
            max-width: 1600px;
        }

        .product h1 {
            font-size: 36px;
        }
    }
`;
