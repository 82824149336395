import React, { useMemo, useState, useCallback } from 'react'
import axios from 'axios'
import {commonUrls} from '../constants/urls'
import querySearchBuilder from './utility/querySearchBuilder';
import { acceptTncCall, applyCoupon, attemptPaymentCall, createDraftCall, sendEmailApi, updateDraftCall, validateDraftCall, zendeskApi, zohoApi } from './draft';

export const useDomesticOriginsList = () => {
    const [data, setData]=useState([]);
    const [dataLoading, setDataLoading]=useState(false);
  
    const getDestinations = async () => {
      setDataLoading(true)
  
      await axios.get(commonUrls.getDomesticOriginList, {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data)
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { domesticOriginListOptions: data , dataLoading , getDestinations }
}

export const useDomesticDestinationsList = () => {
    const [data, setData]=useState([]);
    const [dataLoading, setDataLoading]=useState(false);
  
    const getDomesticDestinations = async () => {
      setDataLoading(true)
  
      await axios.get(commonUrls.getDomesticDestinationList, {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data)
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { domesticDestinationList: data , dataLoading , getDomesticDestinations }
}

export const useCategorizedCountryList = () => {
    const [data, setData]=useState([]);
    const [dataLoading, setDataLoading]=useState(false);
  
    const getCountryList = async () => {
      setDataLoading(true)
  
      await axios.get(commonUrls.getCategorisedCountryList, {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
            const remappedData = [
                ...data.data.favourites.map((x) => ({
                  ...x,
                  country_code: x.country_code.toUpperCase(),
                })),
                ...data.data.other.map((x) => ({
                  ...x,
                  country_code: x.country_code.toUpperCase(),
                })),
              ];
          setData(remappedData)
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { countryList: data , dataLoading , getCountryList }
}

export const useGetFAQDoc = () => {
    const [data, setData]=useState([]);
    const [dataLoading, setDataLoading]=useState(false);
  
    const getFAQDoc = async (params) => {
      let staticDataParams = {}

      if (!params) staticDataParams =  {}
      else staticDataParams = querySearchBuilder(params)
    
      setDataLoading(true)
  
      await axios.get(commonUrls.getBenefitDoc(staticDataParams), {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data?.[0])
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , getFAQDoc }
}
export const useGetTncDoc = () => {
    const [data, setData]=useState([]);
    const [dataLoading, setDataLoading]=useState(false);
  
    const gettnCDoc = async (params) => {
      let staticDataParams = {}

      if (!params) staticDataParams =  {}
      else staticDataParams = querySearchBuilder(params)
    
      setDataLoading(true)
  
      await axios.get(commonUrls.getBenefitDoc(staticDataParams), {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data?.[0])
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , gettnCDoc }
}

export const useGetClaimDoc = () => {
    const [data, setData]=useState([]);
    const [dataLoading, setDataLoading]=useState(false);
  
    const getClaimData = async (params) => {
      let staticDataParams = {}

      if (!params){
        staticDataParams =  {}
      } else {
        staticDataParams = querySearchBuilder(params)
      }
    
      setDataLoading(true)
  
      await axios.get(commonUrls.getBenefitDoc(staticDataParams), {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data?.[0])
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , getClaimData }
}

export const useGetBenefitDoc = () => {
    const [data, setData]=useState([]);
    const [dataLoading, setDataLoading]=useState(false);
  
    const getBenefitDoc = async (params) => {
      let staticDataParams = {}

      if (!params) staticDataParams =  {}
      else staticDataParams = querySearchBuilder(params)
    
      setDataLoading(true)
  
      await axios.get(commonUrls.getBenefitDoc(staticDataParams), {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data?.[0])
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , getBenefitDoc }
}

export const useGetDraftData = () => {
  const [data, setData]=useState([]);
  const [dataLoading, setDataLoading]=useState(false);
  
    const getTravelDraftData = async (draft_id) => {
      setDataLoading(true)
  
      await axios.get(commonUrls.getTravelDraftData(draft_id), {
        headers: { 'Content-Type': 'application/json'},
        }).then(({data}) => {
          setData(data?.data)
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , getTravelDraftData }
}

export const useEvaluateProduct = () => {
  const [data, setData]=useState([]);
  const [dataLoading, setDataLoading]=useState(false);

  const getPackageList = async (productCode, pkgParams) => {
    setDataLoading(true)

    const params = {
      product_code: productCode,
      payload: pkgParams
    }

    await axios.post(commonUrls.getPackageList, params, {
      headers: { 'Content-Type': 'application/json'}
      }).then((data) => {
        setData(data.data)
    }).catch((error) => {
      setData(error);
    })
    setDataLoading(false);
  }
    
  return { data: data , dataLoading , getPackageList }
}

export const useSaticData = (params) => {
  const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
  
    const getStaticData = async () => {
      setDataLoading(true)

      await axios.get(commonUrls.getPreviewData(params.product_code, params.data_type), {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data?.[0])
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , getStaticData }
}

export const useGetProvice = () => {
  const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
  
    const getProvinceList = async () => {
      setDataLoading(true)

      await axios.get(commonUrls.getProviceList, {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data)
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , getProvinceList }
}

export const useGetWorkshopProvince = () => {
  const [data,setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState(null)

  const getProvinceList = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const response = await axios.get(commonUrls.getWorkshopProvince)
      setData(response.data)
    } catch (error) {
      setError(error.response ? error.response.data : 'Failed to fetch Province');
    } finally {
      setLoading(false)
    }
  },[])

  return {data, loading, error, getProvinceList}
}

  export const useGetWorkshopCityList = () => {
    const [data,setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)

    const getCityList = useCallback(async (province) => {
      setLoading(true)
      setError(null)

      try {
        const response = await axios.get(commonUrls.getWorkshopCityList(province))
        setData(response.data)
      } catch (error) {
        setError(error.response ? error.response.data : 'Failed to fetch City');
      } finally {
        setLoading(false)
      }
    },[])

    return {data, loading, error, getCityList}
  }

  export const useGetWorkshopList = () => {
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage,setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getWorkshopList = useCallback(async (city, page, type) => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(commonUrls.getWorkshopList(city, page, type));
            setData(response.data.data);  // Only workshop data
            setTotalData(response.data.total_records);
            setCurrentPage(response.data.page)
            setMaxPage(response.data.num_pages)
        } catch (error) {
            setError(error.response ? error.response.data : 'Failed to fetch Workshop');
        } finally {
            setLoading(false);
        }
    }, []);

    return { data, totalData, maxPage, currentPage, loading, error, getWorkshopList };
};


export const useGetKota = () => {
  const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
  
    const getKotaList = async (province) => {
      setDataLoading(true)

      await axios.get(commonUrls.getKotaList(province), {
        headers: { 'Content-Type': 'application/json'}
        }).then((data) => {
          setData(data.data)
      }).catch((error) => {
        setData(error);
      })
      setDataLoading(false);
    }
    
    return { data: data , dataLoading , getKotaList }
}

export const useCreateDraft = ({ onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const createDraft = async (payload) => {
    try {
      setIsLoading(true)
      const res = await createDraftCall(payload)
      setIsLoading(false)
      setResponse(res)
      onSuccess(res.data?.data?.ref_id)
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    createDraft
  }
}

export const useUpdateDraft = ({ draftId, step, onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const updateDraft = async (payload) => {
    try {
      setIsLoading(true)

      const finalPayload = {
        ...payload,
        "step": step,
        "ref_id": draftId
      }

      const res = await updateDraftCall(finalPayload)
      setIsLoading(false)
      setResponse(res)
      onSuccess()
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    updateDraft
  }
}

export const useValidateDraft = ({ productCode, draftId, onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const validateDraft = async () => {
    try {
      setIsLoading(true)

      const finalPayload = {
        "ref_id": draftId,
        "product_code": productCode
      }

      const res = await validateDraftCall(finalPayload)
      setIsLoading(false)
      setResponse(res)
      onSuccess()
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    validateDraft
  }
}


export const useApplyCoupon = ({ inputPromo, ref_id, onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const validateCoupon = async () => {
    try {
      setIsLoading(true)

      const finalPayload = {
        "code": inputPromo,
        ref_id
      }

      const res = await applyCoupon(finalPayload)
      setIsLoading(false)
      setResponse(res)

      onSuccess(res)
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    validateCoupon
  }
}

export const useAcceptTnc = ({ onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const acceptTnc = async (productCode, draftId, staticRefId) => {
    try {
      setIsLoading(true)

      const finalPayload = {
        "product_code": productCode,
        "ref_id": draftId,
        "static_data_ref_id": staticRefId
      }

      const res = await acceptTncCall(finalPayload)
      setIsLoading(false)
      setResponse(res)
      onSuccess()
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    acceptTnc
  }
}


export const  useGetPaymentMethods = () => {
  const [data, setData]=useState([]);
  const [dataLoading, setDataLoading]=useState(false);

  const getPaymentMethods = async (params) => {

    await axios.get(commonUrls.getPaymentMethods, {
      headers: { 'Content-Type': 'application/json'}
      }).then((data) => {
        let remappedData = [];
        if (Object.values(data?.data?.data)) {
          remappedData = [...Object.values(data?.data?.data).sort((a, b) => a.key > b.key ? 1 : -1)]
        }
        setData(remappedData)
    }).catch((error) => {
      setData(error);
    })
    setDataLoading(false);
  }
  
  return { data: data , dataLoading , getPaymentMethods }
}

export const usePaymentStatus = (draftId) => {
  const [data, setData]=useState([]);
  const [dataLoading, setDataLoading]=useState(false);

  const getPaymentStatus = async (draftId) => {

    await axios.get(commonUrls.getPaymentStatus(draftId), {
      headers: { 'Content-Type': 'application/json'}
      }).then((data) => {
        setData(data?.data)
    }).catch((error) => {
      setData(error);
    })
    setDataLoading(false);
  }
  
  return { data: data , dataLoading , getPaymentStatus }
}

export const useAttemptPayment = ({ onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const attemptPayment = async (payload) => {
    try {
      setIsLoading(true)
      const res = await attemptPaymentCall(payload)
      setIsLoading(false)
      setResponse(res)
      onSuccess(res.data)
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    attemptPayment
  }
}

export const useGetFinanceReports = ({year}) => {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getFinanceReport = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(commonUrls.getFinanceReport(year));
      setReportData(response.data);
    } catch (err) {
      setError('Failed to fetch reports.');
    } finally {
      setLoading(false);
    }
  }, [year]);
  
  return {reportData, loading, error, getFinanceReport}
};

export const useCustomerSupport = ({ onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const zendeskApiCall = async (payload) => {
    try {
      setIsLoading(true)

      const res = await zendeskApi(payload)
      setIsLoading(false)
      setResponse(res)
      onSuccess()
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    zendeskApiCall
  }
}
export const useInquiryApi = ({ onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const inquiryApiCall = async (payload) => {
    try {
      setIsLoading(true)

      const res = await zohoApi(payload)
      setIsLoading(false)
      setResponse(res)
      onSuccess()
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    inquiryApiCall
  }
}
export const useSendEmail = ({ onSuccess, onFailure }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({})

  const sendEmailApiCall = async (payload) => {
    try {
      setIsLoading(true)

      const res = await sendEmailApi(payload)
      setIsLoading(false)
      setResponse(res)
      onSuccess()
    } catch (e) {
      setIsLoading(false)
      setIsError(false)
      onFailure(e)
      console.error(e)
    }
  }

  return {
    isLoading,
    isError,
    response,
    sendEmailApiCall
  }
}