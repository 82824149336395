import FinanceReport from "../../containers/FinanceReport"
const mainPath = '/publication'

const customerSupportroutes = [
  {
    path: 'finance-report',
    element: <FinanceReport />
  },

].map((route) => ({ ...route, path: `${mainPath}/${route.path}` }))

export default customerSupportroutes