import { useState } from "react"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
  body1List,
  body2ListContentTowTruck,
  body2ListContentOnsiteAccident,
  body3ListContent,
  imagesLink,
  langStrings,
  riplayDocUrl,
  PROPERTY_TYPE
} from "./constants"
import {
  BannerContainer,
  BannerContent,
  BannerForegroundImage,
  BannerForegroundImageContainer,
  BannerSubTitle,
  BannerTitle,
  Body1Container,
  Body1ContainerList,
  Body1Content,
  Body1Heading,
  Body1InfoContainerOrderedList,
  Body1SideImage,
  Body1SideImageContainer,
  Body1UnorderedList,
  Body1UnorderedListContainer,
  Body2Container,
  Body2HeadingText,
  Body2ImageContainer,
  Body2ImageHolder,
  Body2ImagesContainer,
  Body2OrderedList,
  Body2OrderedListContainer,
  Body2OrderedListHeading,
  Body2OrderedListText,
  Body3Container,
  Body3Content,
  Body3ImageContainer,
  Body3ImageHeading,
  Body3ImageHolder,
  Body3ListContainer,
  Body3ListItem,
  Body4BannerImage,
  Body4Button,
  Body4ButtonContainer,
  Body4ButtonImage,
  Body4Container,
  Body4Heading,
  Body4ImageContainer,
  Body4SubTitle,
  Body4TextContainer,
  Body5Container,
  Body5TextContainer,
  Body5Heading,
  Body5SubTitle,
  BodyContainer,
  InquiryFormDiv,
  AnchorTag,
  ProductListWrapper,
  ProductListIconWrapper,
  ProductListIconContainer,
  ProductListIcon,
  ProductListTitle
} from "./roadAssistance.style"
import InquiryForm from "../userCriteria/inquiryForm"
import ScrollToTop from "../../components/ScrollToTop"
import { ImageUrl } from "../../Images"

function RoadAssistance(props) {
  const [choosenOption, setChoosenOption] = useState(0)
  const [activeProduct, setActiveProduct] = useState("derek");
  const body2ListContent = activeProduct === 'derek' ? body2ListContentTowTruck : body2ListContentOnsiteAccident
  return (
    <div>
      <Header />
      <BannerContainer>
        <BannerSubTitle>
        <img src = {imagesLink.autoText}/>
        </BannerSubTitle>
        <BannerTitle>
          {langStrings.roadAssistance}
        </BannerTitle>


        <BannerContent>
          <BannerForegroundImageContainer>
            <BannerForegroundImage src={imagesLink.roadAssistanceBannerImage} />
          </BannerForegroundImageContainer>
        </BannerContent>

      </BannerContainer>
      <BodyContainer>
        <Body1Container>
          <Body1Heading>
            {langStrings.body1HeadingText}
          </Body1Heading>
          <Body1Content>
            <Body1SideImageContainer>
              <Body1SideImage
                src={imagesLink.manTryingToRepair}
              />
            </Body1SideImageContainer>
            <Body1InfoContainerOrderedList>
              {body1List.map(({ text, children = [] }) => {
                return (
                  <Body1ContainerList>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                    <Body1UnorderedListContainer>
                      {children.map(({ text }) => {
                        return (
                          <Body1UnorderedList>
                            {text}
                          </Body1UnorderedList>
                        )
                      })}
                    </Body1UnorderedListContainer>
                  </Body1ContainerList>
                )
              })}
            </Body1InfoContainerOrderedList>
          </Body1Content>

        </Body1Container>

        <Body2Container>
          <Body2HeadingText>{langStrings.body2HeadingText}</Body2HeadingText>
          <ProductListWrapper>
            {PROPERTY_TYPE.map((product) => {
              return (
                <ProductListIconWrapper
                  onClick={() => {
                    setActiveProduct(product.productName);
                  }}
                >
                  <ProductListIconContainer
                    active={activeProduct === product.productName}
                  >
                    <ProductListIcon src={product.activeIcon} alt={product.key} />
                  </ProductListIconContainer>
                  <ProductListTitle
                    isActive={activeProduct === product.productName}
                  >
                    {product.label}
                  </ProductListTitle>
                </ProductListIconWrapper>
              );
            })}
          </ProductListWrapper>
          <Body2OrderedListContainer>
            {
              body2ListContent.map(({ heading, text }) => {
                return (
                  <Body2OrderedList>
                    <Body2OrderedListHeading>
                      {heading}
                    </Body2OrderedListHeading>
                    {text.map((t) => {
                      return (
                        <Body2OrderedListText>
                          {t}
                        </Body2OrderedListText>
                      )
                    })}
                  </Body2OrderedList>
                )
              })
            }
          </Body2OrderedListContainer>
        </Body2Container>

        <Body3Container>
          <Body3ImageContainer>
            <Body3ImageHolder src={imagesLink.manHoldingIpad} />
          </Body3ImageContainer>

          <Body3Content>
            <Body3ImageHeading>
              {langStrings.termsAndCondition}
            </Body3ImageHeading>
            <Body3ListContainer>
              {
                body3ListContent.map(({ text }) => {
                  return (
                    <Body3ListItem>
                      {text}
                    </Body3ListItem>
                  )
                })
              }
            </Body3ListContainer>
          </Body3Content>
        </Body3Container>

        <Body4Container>
          <Body4TextContainer>
            <Body4Heading>{langStrings.body4Heading}</Body4Heading>
            <Body4SubTitle>{langStrings.protectWithSmallSteps}</Body4SubTitle>
            <AnchorTag href={riplayDocUrl} target="_blank">
              <Body4ButtonContainer>
                <Body4Button>{langStrings.downloadProductInformation}</Body4Button>
                <Body4Button
                  padding="12px 12px"
                >
                  <Body4ButtonImage
                    src={ImageUrl.DownloadWhite}
                  />
                </Body4Button>
              </Body4ButtonContainer>
            </AnchorTag>
          </Body4TextContainer>
          <Body4ImageContainer>
            <Body4BannerImage
              src={imagesLink.beachForeground}
            />
          </Body4ImageContainer>
        </Body4Container>
        <Body5Container>
          <Body5TextContainer>
              <Body5Heading>{langStrings.body5Heading}</Body5Heading>
              <Body5SubTitle>{langStrings.protectYouWithSmallSteps}</Body5SubTitle>
              <InquiryFormDiv>
                <InquiryForm product={"Asuransi Auto"}/>
              </InquiryFormDiv>
          </Body5TextContainer>
        </Body5Container>

      </BodyContainer>
      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default RoadAssistance