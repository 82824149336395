import React, { useEffect, useState } from "react";
import {
  HeaderRouteCtn,
  HeaderRouteWrapper,
  HeaderTitle,
  PoliciesInfoCtn,
  TravelHeaderCTN,
  TravelHeaderWrapper,
  Title,
  HeaderRouteCtnMobile,
  HamburgerWrapper,
  HamburgerCtn,
  HamburgerTop,
  HamburgerListCtn,
  HambergerMenuItem,
  ImageTag,
} from "./elements";
import Modal from "../Modal";
import PDFPreview from "../PDFPreview";
import { ImageUrl } from "../../Images";
import DetailsTabs from "../DetailsTabs";
import { useGetClaimDoc, useGetFAQDoc, useGetTncDoc } from "../../lib/api";
import TermsContent from "../AppTerms/TermsContent";
import ProductFAQ from "../ProductFAQ";
import { useNavigate } from "react-router-dom";

const HeaderRoute = [
  {
    title: "RIPLAY Produk",
    icon: ImageUrl.Sheild 
  },
  {
    title: "Cara Klaim",
    icon: ImageUrl.Bag 
  },
  {
    title: "Syarat & Ketentuan",
    icon: ImageUrl.Notes 
  },
  {
    title: "FAQ",
    icon: ImageUrl.FAQ 
  },
];

const TravelHeader = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currActiveTab, setCurrActiveTab] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const { data: claimData, getClaimData = () => {} } = useGetClaimDoc();
  const { data: tncData, gettnCDoc = () => {} } = useGetTncDoc();
  const { data: faqData, getFAQDoc = () => {} } = useGetFAQDoc();

  useEffect(() => {
    const currpackage = localStorage.getItem("currPackage");
    if (currpackage) {
      getClaimData({
        product_code: "aus-travel-insurance",
        package_code: currpackage,
        data_type: "CLAIM_PROCESS",
      });
      getFAQDoc({ product_code: "aus-travel-insurance", data_type: "FAQ" });
      gettnCDoc({ product_code: "aus-travel-insurance", data_type: "TNC" });
    }
  }, [localStorage.getItem("currPackage")]);

  const tabs = [
    {
      id: "travel-benefits-tab",
      name: "RIPLAY Produk",
      displayName: "RIPLAY Produk",
      component: (
        <PoliciesInfoCtn>
          <Title>Ringkasan Produk & Layanan TAP Travel</Title>
          <PDFPreview src="https://storage.googleapis.com/pp_img/consumer/Summary%20of%20Product%20and%20Service%20Information%20-%20TAP%20Travel_V5.pdf" />
        </PoliciesInfoCtn>
      ),
    },
    {
      id: "travel-claim-guide-tab",
      name: "Klaim",
      displayName: "Cara Klaim",
      component: (
        <PoliciesInfoCtn>
          <TermsContent termsData={claimData} getter={(x) => x?.data} />
        </PoliciesInfoCtn>
      ),
    },
    {
      id: "travel-terms-tab",
      name: "S&K",
      displayName: "Syarat & Ketentuan",
      component: (
        <PoliciesInfoCtn>
          <TermsContent termsData={tncData} getter={(x) => x?.data} />
        </PoliciesInfoCtn>
      ),
    },
    {
      id: "travel-faq-tab",
      name: "FAQ",
      displayName: "FAQ",
      component: (
        <PoliciesInfoCtn>
          <Title>Daftar pertanyaan sering diajukan</Title>
          <ProductFAQ data={faqData} getter={(x) => x?.data} />
        </PoliciesInfoCtn>
      ),
    },
  ];

  const handleClick = (ind) => {
    setShowModal(true);
    setCurrActiveTab(ind);
  };

  const HandleHamburgerListClick = (ind) => {
    setShowMenu(false)
    setShowModal(true);
    setCurrActiveTab(ind);
  }

  const goHome = () => {
    navigate({
      pathname: "/",
    });
  };

  return (
    <TravelHeaderCTN>
      <TravelHeaderWrapper>
        <ImageTag src={ImageUrl.TAP} width="104px" height="32px" alt="tap-icon" onClick={goHome}/>
        <HeaderRouteCtn>
          <HeaderRouteWrapper>
            {HeaderRoute.map((route, ind) => {
              return (
                <div>
                  <HeaderTitle onClick={() => handleClick(ind)}>
                    {route.title}
                  </HeaderTitle>
                </div>
              );
            })}
          </HeaderRouteWrapper>
        </HeaderRouteCtn>
        <HeaderRouteCtnMobile>
          <img
            src={ImageUrl.MenuIcon}
            width="20px"
            height="20px"
            alt="memu"
            onClick={() => setShowMenu(true)}
          />
        </HeaderRouteCtnMobile>
      </TravelHeaderWrapper>
      {showMenu ? (
        <HamburgerWrapper>
          <HamburgerCtn>
            <HamburgerTop>
              <Title>Menu</Title>
              <img src={ImageUrl.Cross} width='20px' height='20px' alt="cross" onClick={() => setShowMenu(false)}/>
            </HamburgerTop> 
            <HamburgerListCtn>
              {HeaderRoute.map((route, ind) => {
                return (
                  <HambergerMenuItem onClick={() => HandleHamburgerListClick(ind)}>
                    <img src={route.icon} width='40px' height='40px' alt="icons-menu"/>
                    <Title>{route.title}</Title>
                  </HambergerMenuItem>
                );
              })}
            </HamburgerListCtn>
          </HamburgerCtn>
        </HamburgerWrapper>
      ) : null}
      <Modal
        title={"Informasi"}
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <div style={{ padding: "20px" }}>
          <DetailsTabs tabs={tabs} active={tabs[currActiveTab]} />
        </div>
      </Modal>
    </TravelHeaderCTN>
  );
};

export default TravelHeader;
