import React, { useCallback, useEffect, useMemo } from "react";
import TravelHeader from "../../components/TravelHeader";
import TravelFooter from "../../components/TravelFooter";
import PaymentSucessFull from "./components/paymentSucessfull";
import PaymentPending from "./components/paymentPending";
import PaymentExpired from "./components/paymentExpired";
import { BounceLoaderCtn, FooterTextCtn, PaymentBoldText } from "./elements";
import { ImageUrl } from "../../Images";
import { FormattedMessage } from "react-intl";
import { usePaymentStatus } from "../../lib/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PaymentUnpaid from "./components/paymentUnpaid";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: paymentStatusData,
    dataLoading,
    getPaymentStatus,
  } = usePaymentStatus();

  useEffect(() => {
    searchParams.get("draft_id") && getPaymentStatus(searchParams.get("draft_id"));
  }, []);

  const _getFooter = () => {
    return (
      <FooterTextCtn>
        <img src={ImageUrl.BlueInfo} width="32px" height="32px" alt="info" />
        <FormattedMessage
          id="Butuh bantuan? Silakan menghubungi WhatsApp 0818-889-019 atau Email cs@tap-insure.com"
          defaultMessage="Butuh bantuan? Silakan menghubungi <b>WhatsApp 0818-889-019</b> atau <b>Email cs@tap-insure.com</b>"
          values={{
            b: (chunks) => <PaymentBoldText>{chunks}</PaymentBoldText>,
          }}
        />
      </FooterTextCtn>
    );
  };

  const paymentPage = useCallback(() => {
    if (
      paymentStatusData?.data?.status === "completed" &&
      paymentStatusData?.data?.app_number
    ) {
      if (paymentStatusData?.data?.app_number) {
        return <PaymentSucessFull data={paymentStatusData?.data} />;
      } else {
        <PaymentPending data={paymentStatusData?.data} />
      }
    } else if (paymentStatusData?.data?.status === "rejected") {
      return  <PaymentExpired data={paymentStatusData?.data} />;
    } else {
      return <PaymentUnpaid data={paymentStatusData?.data} />;
    }
  }, [paymentStatusData]);

  const goHome = () => {
    navigate({
      pathname: "/",
    });
  };

  return !dataLoading && paymentStatusData && paymentStatusData?.data ? (
    <div>
      <TravelHeader />
      {paymentPage()}
      <TravelFooter
        leftContent={_getFooter}
        showPremium={false}
        btnText="Ke Beranda"
        onSubmit={goHome}
      />
    </div>
  ) : (
    <BounceLoaderCtn>
      <BounceLoader color="#b33cfa" size={90} />
    </BounceLoaderCtn>
  );
};

export default PaymentStatus;
