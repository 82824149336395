export const imagesLink = {
    ProductHeading: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/other_product_heading.png',
    LiabilityPhoto: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/liability_photo.png',
    LiabilityInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/liability_info.png',
    LiabilityMoreInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/more_info_liability.png',
    LiabilityBanner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/other%20all%20benefits.png',
    ShippingPhoto: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/shipment_photo.png',
    ShippingInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/shipping_info.png',
    ShippingMoreInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/more_info_shipping.png',
    ShippingBanner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/shipping_banner.png',
    EquipmentPhoto: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/equipment_photo.png',
    EquipmentInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/equipment_info.png',
    EquipmentProtection: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/shipping_protection.png',
    EquipmentMoreInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/equipment_more_info.png',
    EquipmentBanner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/other%20benefit.png'
  }
  
  export const PRODUCT_TYPE = [
    {
      key: 'liability',
      activeIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/liability.png',
      label: 'Liability',
      productName:'liability',
    },
    {
      key: 'shipping',
      activeIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/shipping.png',
      label: 'Shipping',
      productName:'shipping',
    },
    {
      key: 'equipment',
      activeIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/equipment.png',
      label: 'Heavy Equipment',
      productName:'equipment',
    }
  ]

export const riplayDocUrl = {
  liability: 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Liability.pdf',
  shipping: 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Shipping.pdf',
  equipment: 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Heavy%20Equipment.pdf'
}